import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import Wrapper from '../../components/Wrapper';
import ContentBox from '../../components/ContentBox';
import { useCallback, useContext, useEffect, useState } from 'react';
import styles from '../../styles.module.scss';
import { Context } from '../../ContextProvider';
import Loader from '../../components/Loader';
import { createFormData } from '../../helpers/createFormData';
import { useAdminSupportBotAddManager } from '../../api/hooks/useAdminSupportBotAddManager';
import { useAdminSupportBotGetManagers } from '../../api/hooks/useAdminSupportBotGetManagers';
import { useAdminSupportBotRemoveManager } from '../../api/hooks/useAdminSupportBotRemoveManager';
import TgSupportStat from '../../components/admin/TgSupportStat';

export default function SupportBot() {
  useCheckRole(roles.admin);

  const { setNotifySuc } = useContext(Context);
  const [newUsername, setNewUsername] = useState();
  const [users, setUsers] = useState([]);
  const { isLoading: isLoadingAdd, mutate: mutateAdd } = useAdminSupportBotAddManager();
  const { isLoading: isLoadingGet, mutate: mutateGet } = useAdminSupportBotGetManagers();
  const { isLoading: isLoadingRemove, mutate: mutateRemove } = useAdminSupportBotRemoveManager();

  const userGetting = useCallback(
    () => {
      mutateGet({}, {
        onSuccess: data => setUsers(data),
      });
    },
    [mutateGet]
  )
  useEffect(() => {
    userGetting();
  }, []);

  return(
    <Wrapper>
      <ContentBox>
        <h2>Добавить менеджера</h2>
        <input
          value={newUsername}
          onChange={e => setNewUsername(e.target.value)}
          placeholder={'Ник ТГ пользователя, без @'}
        />
        <button onClick={() => {
          mutateAdd(createFormData({ username: newUsername }), {
            onSuccess: () => {
              setNotifySuc('Менеджер успешно был добавлен');
              userGetting();
            }
          })
        }}>
          Добавить
        </button>

        <div className={styles.row}>
          <div className={styles.item}>
            <b>username</b>
          </div>
          <div className={`${styles.item}`}>
            <b>Удалить</b>
          </div>
        </div>
        {users?.length < 1 && <p>Записи о работе отсутсвуют</p>}
        {
          users?.length > 0 &&
          users?.map(({username, id}) => (
            <div key={`${id}`} className={styles.row}>
              <div className={styles.item}>
                {username}
              </div>
              <div className={`${styles.item}`}>
                <button
                  onClick={() => {
                    mutateRemove(createFormData({ username }), { onSuccess: () => userGetting() });
                  }}
                >
                  Удалить
                </button>
              </div>
            </div>
          ))
        }
        <Loader showing={isLoadingAdd || isLoadingGet || isLoadingRemove} />
      </ContentBox>

      <TgSupportStat />
    </Wrapper>
  );
}