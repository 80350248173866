import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import Wrapper from '../../components/Wrapper';
import ContentBox from '../../components/ContentBox';
import { useGetManagerListLeads } from '../../api/hooks/useGetManagerListLeads';
import { useCallback, useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import { sortByStatus } from '../../helpers/sortByStatus';
import CardCategory from '../../components/CardCategory';
import { statuses } from '../../const/statuses';
import { createFormData } from '../../helpers/createFormData';
import { DatePicker } from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import styles from '../../styles.module.scss';
import OpenLead from '../../components/OpenLead';
import Modal from '../../components/Modal';
import FindLeadForm from '../../components/FindLeadForm';

export default function Lids() {
  useCheckRole(roles.manager);

  const [leades, setLeades] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [needLoader, setNeedLoader] = useState(true);
  const [startDate, setStartDate] = useState(new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  ));
  const [sortOrder, setSortOrder] = useState('DESC');
  const [endDate, setEndDate] = useState(new Date());
  const [itemToScroll, setItemToScroll] = useState();
  const [isFindeLeadOpen, setIsFindeLeadOpen] = useState(false);

  const { mutate } = useGetManagerListLeads();

  const getLids = useCallback(() => {
    setNeedLoader(true);
    mutate(createFormData({
      status: 'all',
      //date: startDate.toISOString(),
      //endDate: endDate.toISOString(),
      sortOrder,
    }), {
      onSuccess: ({ items: d }) => {
        setLeades(sortByStatus(d));
        if (selectedLead) {
          const currentLead = d.find(i => i.id === selectedLead.id);
          setSelectedLead(currentLead);
        }
      }
    });
  }, [mutate, startDate, endDate, selectedLead, setSelectedLead, sortOrder]);

  useEffect(() => {
    getLids();
  }, []);

  useEffect(() => {
    if (Object.keys(leades).length > 0) {
      setNeedLoader(false);
    }
  }, [leades]);

  useEffect(() => {
    if (!selectedLead && itemToScroll) {
      itemToScroll.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }, [selectedLead]);

  return(
    <Wrapper pageName="Лиды" headerContent={
      <>
        {
          selectedLead &&
          <button
            onClick={
              () => {
                const curItem = document.querySelector(`#item${selectedLead.id}`);
                setItemToScroll(curItem);
                setSelectedLead(null);
              }
            }
            className={styles.inlineButton}
          >
            Закрыть карточку
          </button>
        }
        {
          !selectedLead &&
          <button
            onClick={
              () => setIsFindeLeadOpen(true)
            }
          >
            Поиск лида
          </button>
        }
      </>
    }>
      {
        selectedLead && <OpenLead cardOption={0} getLeadsCallback={getLids} lead={selectedLead} />
      }
      {
        <div style={{ display: selectedLead ? 'none' : 'inherit' }}>
          <ContentBox>
            {/*Фильтр лидов по дате <b>последнего обновления статуса</b> лида<br/>
            С
            <DatePicker value={startDate} showLeadingZeros={true} onChange={setStartDate} />
            по
            <DatePicker value={endDate} showLeadingZeros={true} onChange={setEndDate} />*/}
            <div className={styles.marginTop1Rem}>
              Сортировка по дате:
              <select onChange={e => setSortOrder(e.target.value)}>
                <option value='DESC'>От новых к старым</option>
                <option value='ASC'>От старых к новым</option>
              </select>
            </div>
            <button className={styles.inlineButton} onClick={() => getLids()}>Отобразить</button>
          </ContentBox>
          <div style={{ position: 'relative' }}>
            {statuses.map((i, c) => {
              const title = `${i} [${leades[c]?.length || 0} номеров]`;
              const leadsInThisStatus = leades[c];
              return (
                <CardCategory
                  key={ i }
                  status={ c }
                  title={ c === 0 ?
                    <div>
                      { title }<br/>
                      <span style={ { fontSize: '1rem' } }>
                        К статусу "новый лид" не применяются фильтры с датой, т.к. у "новых лидов" еще нет даты обновления
                      </span>
                    </div> :
                    <div>
                      {title}
                    </div>
                  }
                  leads={leadsInThisStatus}
                  getLeads={getLids}
                  setSelectedLead={setSelectedLead}
                />
              )
            })}
            <Loader showing={needLoader} />
          </div>
        </div>
      }
      <Modal title='Поиск лида' parentSetter={setIsFindeLeadOpen} isOpen={isFindeLeadOpen}>
        <FindLeadForm modalParentSetter={setIsFindeLeadOpen} setSelectLead={setSelectedLead} />
      </Modal>
    </Wrapper>
  );
}