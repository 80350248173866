import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useGetAdminPathToTxt = () => {
  return useMutation(
    'GetAdminListLeads',
    data =>
      ApiService.request({ url: '/adminGenerateLeadsTxt.php', data }),
  );
};
