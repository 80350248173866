import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminBlockUser = () => {
  return useMutation(
    'useAdminBlockUser',
    data =>
      ApiService.request({ url: '/adminBlockUser.php', data }),
  );
};
