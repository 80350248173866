import Wrapper from '../../components/Wrapper';
import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import ContentBox from '../../components/ContentBox';
import styles from '../../styles.module.scss';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useGetManagers } from '../../api/hooks/useGetManagers';
import Loader from '../../components/Loader';
import { useGetAdminListLeads } from '../../api/hooks/useGetAdminListLeads';
import { createFormData } from '../../helpers/createFormData';
import { sortByStatus } from '../../helpers/sortByStatus';
import { statuses } from '../../const/statuses';
import CardCategory from '../../components/CardCategory';
import { normalizeManagers } from '../../helpers/normalizeManagers';
import { Context } from '../../ContextProvider';
import OpenLead from '../../components/OpenLead';
import FindLeadForm from '../../components/FindLeadForm';
import Modal from '../../components/Modal';
import SelectedLeadsOptions from '../../components/SelectedLeadsOptions';
import ExportLids from '../../components/ExportLids';

export default function LidsBase() {
  useCheckRole(roles.admin);

  const { managers, setManagers, setSelectedLeads, setUpdateParentResources, openedManager, setOpenedManager } = useContext(Context);

  const [sortOrder, setSortOrder] = useState('DESC');
  const [isLeadDistributed, setIsLeadDistributed] = useState('false');
  const [managerId, setManagerId] = useState('null');
  const [leads, setLeads] = useState([]);
  const [countLeads, setCountLeads] = useState(0);
  const [selectedLead, setSelectedLead] = useState();
  const [isFindeLeadOpen, setIsFindeLeadOpen] = useState(false);
  const [itemToScroll, setItemToScroll] = useState();

  const {
    mutate: mutateGetManagers,
    isLoading: isLoadingGetManagers
  } = useGetManagers();

  const {
    mutate,
    isLoading
  } = useGetAdminListLeads();

  useEffect(() => {
    mutateGetManagers({}, {
      onSuccess: d => setManagers(normalizeManagers(d)),
    });
  }, []);

  const getLeads = useCallback((customManagerId) => {
    mutate(
      createFormData({
        sortOrder,
        managerId: customManagerId || isLeadDistributed || managerId,
      }),
      {
        onSuccess: d => {
          setLeads(sortByStatus(d.items));
          setCountLeads(d.count);
          setSelectedLeads([])
          setOpenedManager();
          if (selectedLead) {
            const currentLead = d.find(i => i.id === selectedLead.id);
            setSelectedLead(currentLead);
          }
        },
      }
    );
  }, [sortOrder, isLeadDistributed, managerId, mutate, setSelectedLead, selectedLead]);

  useEffect(() => {
    if (openedManager) {
      setManagerId(openedManager);
      getLeads(openedManager);
    }
  }, [openedManager, getLeads]);

  useEffect(() => {
    setUpdateParentResources(() => getLeads);
  }, [getLeads, setUpdateParentResources])

  useEffect(() => {
    getLeads();
  }, []);

  useEffect(() => {
    if (!selectedLead && itemToScroll) {
      itemToScroll.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }, [selectedLead]);

  return (
    <Wrapper
      pageName={`Лиды (${countLeads})`}
      headerContent={
      <>
        {!selectedLead && <ExportLids />}
        {
          selectedLead &&
          <button
            onClick={
              () => {
                const curItem = document.querySelector(`#item${selectedLead.id}`);
                setItemToScroll(curItem);
                setSelectedLead(null);
              }
            }
            className={styles.inlineButton}
          >
            Закрыть карточку
          </button>
        }
        {
          !selectedLead &&
          <button
            onClick={
              () => setIsFindeLeadOpen(true)
            }
          >
            Поиск лида
          </button>
        }
      </>
    }>
      {
        selectedLead && <OpenLead cardOption={2} getLeadsCallback={getLeads} lead={selectedLead} />
      }
      {
        <div style={{ display: selectedLead ? 'none' : 'inherit' }}>
          <ContentBox>

            <h2>Фильтр выбора лидов</h2>

            <div className={styles.marginTop1Rem}>
              Распределен ли лид?
              <select value={isLeadDistributed} onChange={e => setIsLeadDistributed(e.target.value)}>
                <option value={''}>Все лиды</option>
                <option value={true}>Только распределенные</option>
                <option value={'false'}>Только не распределенные</option>
              </select>
            </div>

            <div className={styles.marginTop1Rem}>
              Назначен на менеджера
              <select onChange={e => {
                setIsLeadDistributed();
                setManagerId(e.target.value);
              }
              }>
                <option value={'null'}>Любого (в т.ч. ни на кого)</option>
                {
                  Object.keys(managers).map(key =>
                    <option selected={managers[key].id === managerId} key={key} value={key}>{managers[key].login}</option>
                  )
                }
              </select>
            </div>

            <div className={styles.marginTop1Rem}>
              Сортировка по дате:
              <select onChange={e => setSortOrder(e.target.value)}>
                <option value='DESC'>От новых к старым</option>
                <option value='ASC'>От старых к новым</option>
              </select>
            </div>
            <Loader showing={isLoadingGetManagers} />
            <button style={{marginTop: '1rem'}} onClick={() => getLeads()}>Применить</button>
          </ContentBox>

          <div style={{ position: 'relative' }}>
            {statuses.map((i, c) => {
              const leadsInThisStatus = leads[c];
              const title = `${i} [${leadsInThisStatus?.length || 0} номеров]`
              return (
                <CardCategory
                  key={ i }
                  status={ c }
                  title={ <div>{title}</div> }
                  leads={leadsInThisStatus}
                  getLeads={getLeads}
                  setSelectedLead={setSelectedLead}
                  cardOption={2}
                  canCardBeSelected={true}
                />
              )
            })}
            <Loader showing={isLoading} />
          </div>
        </div>
      }
      <SelectedLeadsOptions />
      <Modal title='Поиск лида' parentSetter={setIsFindeLeadOpen} isOpen={isFindeLeadOpen}>
        <FindLeadForm modalParentSetter={setIsFindeLeadOpen} setSelectLead={setSelectedLead} />
      </Modal>
    </Wrapper>
  );
}