export const statuses =  [
  'Новый лид',
  'Недозвон',
  'Не озвучен оффер',
  'Озвучен офер',
  'Принимает решение',
  'Выставлен счёт',
  'Частичная оплата',
  'Успешно реализовано',
  'Закрыто и не реализовано',
];