import './styles.css';
import styles from './styles.module.scss';
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import React, { useCallback, useState } from 'react';
import { useTheme } from '../../hooks/useThem';
import Loader from '../Loader';
import LeadsSelect from '../LeadsSelect';
import { useGetAdminPathToTxt } from '../../api/hooks/useGetAdminPathToTxt';
import { createFormData } from '../../helpers/createFormData';

export default function ExportLids() {
  const {theme} = useTheme();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [selectedStatus, setSelectedStatus] = useState();
  const [data, setData] = useState();

  const { mutate, isLoading } = useGetAdminPathToTxt();

  const handleDownload = useCallback(() => {
    const link = document.createElement('a');
    link.href = data;
    link.download = 'dfdfd.txt';
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [data]);

  return (
    <>
      <button onClick={() => onOpen()}>
        Экспорт лидов
      </button>

      <Modal
        closeButton={
          <div style={{ cursor: 'pointer' }} className={`${styles.close} ${theme === 'dark' ? styles.dark : ''}`}>
            ✕
          </div>
        }
        backdrop="blur"
        isOpen={isOpen}
        onClose={onClose}
        isDismissable={false}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Экспорт лидов</ModalHeader>
              <ModalBody>
                <Loader showing={isLoading} />
                <p>
                  Данный функционал подготовит на экспорт текущую актуальную базу лидов по выбранному статусу. По необходимости можно сделать больше фильтров (по менеджеру и т.д.) или добавить пункт экспорта для выбранных лидов.
                </p>
                <p>
                  Выберите статус, затем нажмите "генерация". После этого сервер будет формировать txt файл - процесс может занять несколько минут - затем появится кнопка "скачать".
                </p>
                <p>
                  <LeadsSelect setSelected={setSelectedStatus} />
                </p>
                {
                  selectedStatus === 'empty' && <p>База с этим фильтром пуста</p>
                }
              </ModalBody>
              <ModalFooter>
                {
                  data &&
                  <Button
                    color="default"
                    variant="light"
                    onPress={handleDownload}
                  >
                    Скачать
                  </Button>
                }
                {
                  selectedStatus &&
                  <Button
                    color="default"
                    variant="light"
                    onPress={() => {
                      mutate(createFormData({ status: selectedStatus }), {
                        onSuccess: d => setData(d?.file),
                      });
                  }}>
                    Генерация
                  </Button>
                }
                <Button color="danger" variant="light" onPress={onClose}>
                  Отмена
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}