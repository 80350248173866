import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useGetAdminLeadsStat = () => {
  return useMutation(
    'useGetAdminLeadsStat',
    data =>
      ApiService.request({ url: '/getAdminLeadsStat.php', data }),
  );
};
