import React, { useContext, useMemo } from 'react';
import { Context } from '../../ContextProvider';
import { routes } from '../../const/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from './MenuItem';
import styles from './styles.module.scss';
import { useLocation } from 'react-router-dom';
import { roles } from '../../const/roles';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faListCheck,
  faGear,
  faRightFromBracket,
  faUserPlus,
  faAddressBook,
  faUsersGear,
  faChartPie,
  faChartLine,
  faSun,
  faMoon,
  faPersonBooth,
  faRobot,
  faFilePdf,
  faHeadset,
} from '@fortawesome/free-solid-svg-icons';
import ApiService from '../../api/ApiService';
import { useTheme } from '../../hooks/useThem';

library.add(
  faAddressCard,
  faListCheck,
  faGear,
  faRightFromBracket,
  faUserPlus,
  faAddressBook,
  faUsersGear,
  faChartPie,
  faChartLine,
  faSun,
  faMoon,
  faPersonBooth,
  faRobot,
  faFilePdf,
  faHeadset,
);

export default function Menu() {
  const {
    user,
    setUser,
    isOpen,
    setIsOpen,
  } = useContext(Context);
  const currentLocation = useLocation().pathname;

  const menuItems = useMemo(() => {
    if (user?.role === roles.manager) {
      return [
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.managerLids ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-address-card"
            />
          ),
          text: "Лиды",
          route: routes.managerLids,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.managerTasks ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-list-check"
            />
          ),
          text: "Задачи",
          route: routes.managerTasks,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.managerSettings ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-gear"
            />
          ),
          text: "Настройки",
          route: routes.managerSettings,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.managerSettings ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-right-from-bracket"
            />
          ),
          text: 'Выйти',
          submenu: [],
          route: routes.login,
          callback: () => {
            localStorage.removeItem('user');
            setUser(null);
            return ApiService.request({ url: '/logout.php' })
          },
        },
      ];
    }

    if (user?.role === roles.admin) {
      return [
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.adminAddLids ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-user-plus"
            />
          ),
          text: "Добавить лиды",
          route: routes.adminAddLids,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.adminLidsBase ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-address-book"
            />
          ),
          text: "База лидов",
          route: routes.adminLidsBase,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.adminUsers ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-users-gear"
            />
          ),
          text: "Пользователи",
          route: routes.adminUsers,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.adminUserHistory ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-chart-pie"
            />
          ),
          text: "История работы",
          route: routes.adminUserHistory,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.adminLoginLog ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-person-booth"
            />
          ),
          text: "История входов",
          route: routes.adminLoginLog
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.adminStat ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-chart-line"
            />
          ),
          text: "Статистика",
          route: routes.adminStat,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.managerSettings ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-gear"
            />
          ),
          text: "Настройки",
          route: routes.managerSettings,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.adminTgBot ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-file-pdf"
            />
          ),
          text: "PDF генератор",
          route: routes.adminTgBot,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === routes.adminSupportBot ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-headset"
            />
          ),
          text: "Бот поддержки",
          route: routes.adminSupportBot,
        },
        {
          icon: (
            <FontAwesomeIcon
              color={currentLocation === '??' ? '#4154f1' : '#899bbd'}
              icon="fa-solid fa-right-from-bracket"
            />
          ),
          text: 'Выйти',
          submenu: [],
          route: routes.login,
          callback: () => {
            localStorage.removeItem('user');
            setUser(null);
            return ApiService.request({ url: '/logout.php' })
          },
        },
      ];
    }

    return [];
  }, [user?.role]);

  const { theme, toggleTheme } = useTheme();

  return (
    <div className={`${styles.menu} ${isOpen ? styles.menuOpened : ''} ${theme === 'dark' ? styles.dark : ''}`}>
      <div className={`${styles.head} ${isOpen ? styles.isOpen : ''} ${theme === 'dark' ? styles.dark : ''}`}>
        <div className={`${styles.accountInfo} ${theme === 'dark' ? styles.dark : ''}`}>
          {isOpen ? (
            <>
              <div title='Логин пользователя'>{user?.login}</div>
              <div title='ID пользователя'># {user?.id}</div>
            </>
          ) : (
            <>{user?.login[0].toUpperCase()}</>
          )}
        </div>
      </div>

      <div className={`${styles.body} ${theme === 'dark' ? styles.dark : ''}`}>
        <div className={`${styles.menuList} ${!isOpen && styles.notIsOpen} ${theme === 'dark' ? styles.dark : ''}`}>
          {menuItems.map(
            (
              {
                icon,
                text,
                route,
                callback,
                stylesItem,
                stylesWrapper,
              },
              index,
            ) => (
              <MenuItem
                callback={callback}
                withoutText={!isOpen}
                key={index}
                text={text}
                icon={icon}
                route={route}
                stylesItem={stylesItem}
                stylesWrapper={stylesWrapper}
              />
            ),
          )}
          <div className={styles.themeToogle} onClick={() => toggleTheme()}>
            {
              theme === 'dark' ?
                <FontAwesomeIcon
                  icon="fa-solid fa-sun"
                /> :
                <FontAwesomeIcon
                  icon="fa-solid fa-moon"
                />
            }
          </div>
        </div>

        <div
          className={`${styles.footer} ${theme === 'dark' ? styles.dark : ''}`}
          onClick={() => {
            setIsOpen(prevState => !prevState);
          }}>
          {isOpen ? '«' : '»'}
        </div>
      </div>
    </div>
  );
}
