import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminSupportBotAddManager = () => {
  return useMutation(
    'useAdminTgBotAddUser',
    data =>
      ApiService.request({ url: '/supportBotAddManager.php', data }),
  );
};
