import Wrapper from '../../components/Wrapper';
import ContentBox from '../../components/ContentBox';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DateTimePicker } from 'react-datetime-picker';
import { useSetTask } from '../../api/hooks/useSetTask';
import { createFormData } from '../../helpers/createFormData';
import { Context } from '../../ContextProvider';
import Loader from '../../components/Loader';
import styles from '../../styles.module.scss';
import { useGetTasks } from '../../api/hooks/useGetTasks';
import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import { useDeleteTask } from '../../api/hooks/useDeleteTask';
import stylesForStatus from '../../components/OpenLead/styles.module.scss';
import { statuses } from '../../const/statuses';
import OpenLead from '../../components/OpenLead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useTheme } from '../../hooks/useThem';
import { formatDate } from '../../helpers/formatDate';

const statusColors = [stylesForStatus.statusColor0, stylesForStatus.statusColor1, stylesForStatus.statusColor2, stylesForStatus.statusColor3, stylesForStatus.statusColor4, stylesForStatus.statusColor5, stylesForStatus.statusColor6, stylesForStatus.statusColor7, stylesForStatus.statusColor8];

library.add(faWhatsapp);

export default function Tasks() {
  useCheckRole(roles.manager);
  const theme = useTheme();
  const { setNotifySuc } = useContext(Context);

  const [date, setDate] = useState(new Date());
  const [text, setText] = useState('');
  const [tasks, setTasks] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);

  const { mutate: mutateSetTask, isLoading: isLoadingSetTask } = useSetTask();
  const { mutate: mutateGetTask, isLoading: isLoadingGetTasks } = useGetTasks();
  const { mutate: mutateDeleteTask, isLoading: isLoadingDeleteTask } = useDeleteTask();

  const sendTask = useCallback(() => {
    mutateSetTask(createFormData({ date: date.toISOString(), text }), {
      onSuccess: () => {
        setNotifySuc('Напоминание успешно установлено!');
        getTasks();
      }
    });
  }, [date, text, setNotifySuc]);

  const getTasks = useCallback(() => {
    mutateGetTask({}, {
      onSuccess: d => {
        setTasks(d);
        if (selectedLead) {
          const currentLead = d.lid.find(i => i.id === selectedLead.id);
          setSelectedLead(currentLead);
        }
      }
    });
  }, [mutateGetTask, selectedLead, setSelectedLead]);

  const deleteTask = useCallback(id => {
    mutateDeleteTask(createFormData({ id }), {
      onSuccess: () => {
        getTasks();
        setNotifySuc('Задача удалена');
      }
    });
  }, [mutateDeleteTask])

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <Wrapper pageName={selectedLead ? `Лид ${selectedLead.name}` : 'Задачи'} headerContent={
      <>
        {
          selectedLead &&
          <button
            onClick={
              () => setSelectedLead(null)
            }
            className={styles.inlineButton}
          >
            Закрыть карточку
          </button>
        }
      </>
    }>

      {/*<ContentBox>
        <h2>Создать напоминание</h2>
        <DateTimePicker onChange={setDate} value={date} showLeadingZeros={true} />
        <input className={styles.marginTop1Rem} placeholder='Текст напоминания' value={text} onChange={d => setText(d.target.value)} />
        <button onClick={() => sendTask()}>Установить напоминание</button>
        <Loader showing={isLoadingSetTask} />
      </ContentBox>*/}

      {
        selectedLead && <OpenLead getLeadsCallback={getTasks} lead={selectedLead} />
      }

      {!selectedLead &&
        <ContentBox>
          <h2>Активные задачи</h2>
          {tasks.length < 1 && <p>Установленные задачи отсутствуют</p>}
          {
            tasks.length > 0 &&
            tasks.map(({ id, date, lid, lid: { name, phone }, text, status, isToday, isShowed }) => (
              <div
                key={id}
                className={`
                  ${styles.row}
                  ${isToday === '1' ? styles.isToday : ''}
                  ${isShowed === '1' ? styles.isShowed : ''}
                `}
              >
                <div className={styles.item}>
                  {formatDate(date)} <br/>
                  (наше Время)
                </div>
                <div className={`${styles.item}`}>
                  {name}
                </div>
                <div className={`${styles.item}`}>
                  <a
                    href={`https://wa.me/${phone.replace(/ /g, '')}`}
                    target='_blank'
                    className={`${styles.phone} ${theme === 'dark' ? styles.dark : ''}`}
                  >
                    <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
                    { phone }
                  </a>
                </div>
                <div className={`${styles.item} ${styles.comment}`}>
                  { text }
                </div>
                <div className={`${styles.item}`}>
                  <div>Текущий статус</div>
                  <div className={statusColors[status]}>{statuses[status]}</div>
                </div>
                <div className={`${styles.item}`}>
                  <button onClick={() => setSelectedLead(lid)}>Открыть карточку</button>
                  {/*<button onClick={() => {
                    deleteTask(i.id);
                  }} className={styles.negativeButton}>Удалить задачу</button>*/}
                </div>
              </div>
            ))
          }
          <Loader showing={isLoadingGetTasks || isLoadingDeleteTask} />
        </ContentBox>
      }

    </Wrapper>
  );
}