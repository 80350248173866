import Wrapper from '../../components/Wrapper';
import { useUpdatePassword } from '../../api/hooks/useUpdatePassword';
import { useCallback, useContext, useState } from 'react';
import { createFormData } from '../../helpers/createFormData';
import { Context } from '../../ContextProvider';
import ContentBox from '../../components/ContentBox';
import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import Loader from '../../components/Loader';
import styles from '../../styles.module.scss';
import { useUpdateManagerSound } from '../../api/hooks/useUpdateManagerSound';

export default function Settings() {
  useCheckRole([roles.manager, roles.admin]);
  const {setNotifySuc, user, setNotifyWarn, setUser} = useContext(Context);
  const [pass, setPass] = useState('');
  const [oldPass, setOldPass] = useState('');
  const [login, setLogin] = useState(user?.login);
  const [isSoundOn, setIsSoundOn] = useState(user?.isSoundOn);
  const { mutate, isLoading } = useUpdatePassword();
  const { mutate: mutateSound, isLoading: isLoadingSound } = useUpdateManagerSound();

  const saveNewPassword = useCallback(() => {
    if (pass.length < 5 || oldPass.length < 5 || login < 5) {
      return setNotifyWarn('Слишком короткие данные в полях');
    }
    mutate(createFormData({pass, oldPass, login}), {
      onSuccess: () => setNotifySuc('Пароль был успешно обновлен'),
      onError: () => setNotifyWarn('Неверно указан старый пароль!'),
    });
  }, [mutate, pass]);

  const saveSoundSettings = useCallback(() => {
    mutateSound(createFormData({isSoundOn}), {
      onSuccess: () => {
        setUser({ ...user, isSoundOn });
        localStorage.setItem('user', JSON.stringify({ ...user, isSoundOn }));
      },
    });
  }, [mutateSound, setUser, user, isSoundOn]);

  return (
    <Wrapper>
      <ContentBox>
        <h2>Звук уведомлений</h2>

        <div className={styles.marginTop1Rem}>
          <select value={isSoundOn} onChange={e => setIsSoundOn(e.target.value)} defaultValue={user?.isSoundOn}>
            <option value='1'>Включить звук</option>
            <option value='0'>Отключить звук</option>
          </select>
        </div>
        <button onClick={saveSoundSettings}>Сохранить</button>
        <Loader showing={isLoadingSound} />
      </ContentBox>

      <ContentBox>
        <h2>Сменить данные входа</h2>
        <div className={styles.marginTop1Rem}>
          <input onChange={e => setLogin(e.target.value)} value={login} placeholder='Введите старый пароль'/>
        </div>
        <div className={styles.marginTop1Rem}>
          <input type='password' onChange={e => setOldPass(e.target.value)} value={oldPass} placeholder='Введите старый пароль'/>
        </div>
        <div className={styles.marginTop1Rem}>
          <input type='password' onChange={e => setPass(e.target.value)} value={pass} placeholder='Введите новый пароль'/>
        </div>
        <button onClick={saveNewPassword}>Обновить пароль</button>
        <Loader showing={isLoading} />
      </ContentBox>
    </Wrapper>
  )
}