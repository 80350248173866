import styles from '../../SelectedLeadsOptions/style.module.scss';
import { createFormData } from '../../../helpers/createFormData';
import Loader from '../../Loader';
import Modal from '../../Modal';
import { useContext, useState } from 'react';
import { Context } from '../../../ContextProvider';
import { useAdminMoveLeadsToManager } from '../../../api/hooks/useAdminMoveLeadsToManager';

export default function ModalChangeManager({ isMoveToManagerOpened, setIsMoveToManagerOpened }) {
  const {
    selectedLeads,
    setNotifyWarn,
    setNotifySuc,
    updateParentResources,
    managers
  } = useContext(Context);
  const [ newManager, setNewManager ] = useState();
  const [ resetStatus, setResetStatus ] = useState(undefined);

  const { mutate: mutateToManager, isLoading: isLoadingToManager } = useAdminMoveLeadsToManager();

  return (
    <Modal title="Переназначить лиды" isOpen={ isMoveToManagerOpened } parentSetter={ setIsMoveToManagerOpened }>
      <div className={ styles.relative }>
        <div>Назначить лиды на менеджера:</div>
        <select onChange={ e => setNewManager(e.target.value) }>
          <option selected disabled>Не выбрано</option>
          <option value='no'>Открепить, перенести в неназначенные</option>
          {
            Object.values(managers).map(({ id, login }) => (
              <option value={ id } key={ id }>{ login }</option>
            ))
          }
        </select>
        <div className={ styles.marginTop1rem }>
          <input className={ styles.wFC } onChange={ e => setResetStatus(e.target.value) } type='checkbox' value={ true } id='check'/>
          <label htmlFor='check'>Сбросить статус до "новый лид"</label>
        </div>
        <button onClick={ () => {
          if (!newManager) {
            return setNotifyWarn('Выберите менеджера на которого будут прикреплены лиды');
          }
          let data = { lids: selectedLeads, target: newManager };
          if (resetStatus) {
            data = {resetStatus,  ...data};
          }
          return mutateToManager(
            createFormData(data),
            {
              onSuccess: () => {
                setIsMoveToManagerOpened(false);
                setNotifySuc('Лиды успешно обновлены');
                updateParentResources();
              }
            }
          )
        } }>
          Отправить
        </button>
        <Loader showing={ isLoadingToManager }/>
      </div>
    </Modal>
  );
}