import React from 'react';
import { ColorRing } from 'react-loader-spinner';

export default function Loader({ showing = false, lockParent = true }) {
  // it will be working only if parent have `position: relative`
  return (
    <>
      {showing && (
        <>
          {!lockParent && (
            <ColorRing
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperClass="blocks-wrapper"
              colors={['#0071dc', '#0071dc', '#0071dc', '#0071dc', '#0071dc']}
            />
          )}
          {lockParent && (
            <div
              style={{
                position: 'absolute',
                background: '#fafafa',
                opacity: '0.8',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2,
                borderRadius: '0.4rem',
              }}>
              <ColorRing
                height="100"
                width="100"
                ariaLabel="blocks-loading"
                colors={['#0071dc', '#0071dc', '#0071dc', '#0071dc', '#0071dc']}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
