import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminAddLeads = () => {
  return useMutation(
    'useAdminAddLeads',
    data =>
      ApiService.request({ url: '/adminAddLeads.php', data }),
  );
};
