import { useMutation } from 'react-query';
import ApiService from '../ApiService';
import { useContext, useMemo } from 'react';
import { Context } from '../../ContextProvider';
import { roles } from '../../const/roles';

export const useUpdateManagerLead = () => {
  const { user: { role } } = useContext(Context);

  const url = useMemo(() => {
    if (role === roles.admin) {
      return '/updateAdminLead.php';
    }
    return '/updateManagerLead.php';
  }, [role]);

  return useMutation(
    'UpdateManagerLead',
    ({ data, params }) =>
      ApiService.request({ url, data, params }),
  );
};
