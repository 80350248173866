import styles from './style.module.scss';
import { useContext, useMemo, useState } from 'react';
import { Context } from '../../ContextProvider';
import Modal from '../Modal';
import { useAdminMoveLeadsToStatus } from '../../api/hooks/useAdminMoveLeadsToStatus';
import { createFormData } from '../../helpers/createFormData';
import Loader from '../Loader';
import { statuses } from '../../const/statuses';
import { useAdminMoveLeadsToManager } from '../../api/hooks/useAdminMoveLeadsToManager';
import { useTheme } from '../../hooks/useThem';
import { useAdminDeleteLeads } from '../../api/hooks/useAdminDeleteLeads';
import ModalChangeStatus from '../admin/ModalChangeStatus';
import ModalChangeManager from '../admin/ModalChangeManager';
import ModalDeleteLead from '../admin/ModalDeleteLead';

export default function SelectedLeadsOptions() {
  const {
    selectedLeads,
    setSelectedLeads,
  } = useContext(Context);

  const [ isMoveToStatusOpened, setIsMoveToStatusOpened ] = useState(false);
  const [ isMoveToManagerOpened, setIsMoveToManagerOpened ] = useState(false);
  const [ isDeleteOpened, setIsDeleteOpened ] = useState(false);

  const count = useMemo(() => selectedLeads.length, [ selectedLeads ]);

  const { theme } = useTheme();
  return (
    <>
      {
        count > 0 &&
        <div className={ `${styles.container} ${theme === 'dark' ? styles.dark : ''}` }>
          Выбрано карточек: { count }
          <div className={ styles.flex }>
            <button
              className={ `${styles.button} ${theme === 'dark' ? styles.dark : ''}` }
              onClick={ () => setSelectedLeads([]) }
            >
              Отменить выделение
            </button>
            <button className={ styles.button } onClick={ () => setIsMoveToStatusOpened(true) }>
              Перенести в статус
            </button>
            <button className={ styles.button } onClick={ () => setIsMoveToManagerOpened(true) }>
              Назначить на менеджера
            </button>
            <button className={ styles.button } onClick={() => setIsDeleteOpened(true)}>
              Удалить
            </button>
          </div>

          <ModalChangeStatus parentSetter={setIsMoveToStatusOpened} isMoveToStatusOpened={isMoveToStatusOpened} />

          <ModalChangeManager setIsMoveToManagerOpened={setIsMoveToManagerOpened} isMoveToManagerOpened={isMoveToManagerOpened} />

          <ModalDeleteLead isDeleteOpened={ isDeleteOpened } setIsDeleteOpened={ setIsDeleteOpened } />
        </div>
      }
    </>
  );
}