import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useSetTask = () => {
  return useMutation(
    'UseSetTask',
    data =>
      ApiService.request({ url: '/setTask.php', data }),
  );
};
