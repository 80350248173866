import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminUnBlockUser = () => {
  return useMutation(
    'useAdminUnBlockUser',
    data =>
      ApiService.request({ url: '/adminUnBlockUser.php', data }),
  );
};
