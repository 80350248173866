import React, { useState, useEffect } from 'react';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/src/notifications.scss';
import './styles.css';
import { ThemeProvider } from './hooks/useThem';

export const Context = React.createContext();

export default function ContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(true);
  const [user, setUser] = useState(null);
  const [notifyWarn, setNotifyWarn] = useState();
  const [notify, setNotify] = useState();
  const [notifySuc, setNotifySuc] = useState();
  const [notifyErr, setNotifyErr] = useState();
  const [taskMessage, setTaskMessage] = useState(null);
  const [managers, setManagers] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [updateParentResources, setUpdateParentResources] = useState();
  const [openedManager, setOpenedManager] = useState();

  useEffect(() => {
    if (notifyWarn) {
      NotificationManager.warning(notifyWarn, '', 5000);
      setNotifyWarn(undefined);
    }
  }, [notifyWarn]);

  useEffect(() => {
    if (notify) {
      NotificationManager.warning(notify, '', 99999999999999999);
      setNotify(undefined);
    }
  }, [notify]);

  useEffect(() => {
    if (notifySuc) {
      NotificationManager.success(notifySuc, '', 5000);
      setNotifySuc(undefined);
    }
  }, [notifySuc]);

  useEffect(() => {
    if (notifyErr) {
      NotificationManager.error(notifyErr, '', 5000);
      setNotifyErr(undefined);
    }
  }, [notifyErr]);

  return (
    <Context.Provider value={{
      user,
      setUser,
      notifySuc,
      setNotifySuc,
      notifyWarn,
      setNotifyWarn,
      notify,
      setNotify,
      notifyErr,
      setNotifyErr,
      isOpen,
      setIsOpen,
      taskMessage,
      setTaskMessage,
      managers,
      setManagers,
      selectedLeads,
      setSelectedLeads,
      updateParentResources,
      setUpdateParentResources,
      openedManager,
      setOpenedManager
    }}>
      <ThemeProvider>
        {children}
      </ThemeProvider>
      <NotificationContainer />
    </Context.Provider>
  );
}