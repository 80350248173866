export function formatDate(inputDate) {
  if (!inputDate) {
    return  '';
  }
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDate = `${(day < 10 ? "0" : "")}${day}.${(month < 10 ? "0" : "")}${month}.${year}`;

  const timePart = inputDate.match(/\d{2}:\d{2}/);
  if (timePart) {
    return formattedDate + " " + timePart[0];
  }

  return formattedDate;
}