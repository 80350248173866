export const routes = {
  login: '/',
  managerLids: '/lids',
  managerTasks: '/tasks',
  managerSettings: '/settings',

  adminAddLids: '/addLids',
  adminLidsBase: '/allLids',
  adminUsers: '/users',
  adminUserHistory: '/userHistory',
  adminStat: '/stat',
  adminLoginLog: '/loginLog',
  adminTgBot: '/tgbot',
  adminSupportBot: '/supportbot',
}