import axios from 'axios';
import { getApiUrl } from '../helpers/getApiUrl';

class ApiService {
  axiosInstanceRest = null;

  instanceTypes = {};

  constructor() {
    this.initStorage();
    this.initApi();
  }

  initApi() {
    this.axiosInstanceRest = axios.create({
      baseURL: getApiUrl(),
    });
    this.axiosInstanceRest.interceptors.request.use(config => {
      config.headers = {
        ...config.headers,
      };

      return config;
    });

    this.instanceTypes = {
      rest: this.axiosInstanceRest,
    };
  }

  initStorage() {

  }

  async request({
                  method = 'post',
                  url,
                  data,
                  params,
                  headers,
                  responseType,
                  type = 'rest',
                }) {
    try {
      const req = await this.instanceTypes[type].request({
        method,
        url,
        data,
        params,
        responseType: responseType || 'json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
          ...headers,
        },
        withCredentials: true,
      });

      if (responseType === 'blob') {
        return { data: req.data, type: req.headers['content-type'] };
      }
      if (req?.data?.errors) {
        if (
          JSON.parse(req?.data?.errors[0]?.message)?.code === 'WrongCredentials'
        ) {
          throw 'tokenExpired';
        }
      }
      return req.data.data || req.data;
    } catch (e) {
      if (e.response?.data?.error === 'denied') {
        return window.location.href = '/cabinet';
      }
      /*console.log('req', e.request);
      console.log('conf', e.config);
      console.log('message', e.message);
      console.log('method', method);*/
      throw e;
    }
  }

  async requestWithFile({ method, url, data }) {
    return this.request({
      method,
      url,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export default new ApiService();
