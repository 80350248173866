import { statuses } from '../const/statuses';

export const sortByStatus = (leads) => {
  const res = {};

  for (let i = 0; i < statuses.length; i++) {
    res[i] = [];
  }

  for (const lead of leads) {
    const { status } = lead;
    res[status].push(lead);
  }

  return res;
}
