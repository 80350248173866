import { Context } from '../ContextProvider';
import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { routes } from '../const/routes';

export const useCheckRole = (role) => {
  const { user, setUser } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      const localUser = localStorage.getItem('user');
      if (!localUser) {
        navigate(routes.login);
      } else {
        setUser(JSON.parse(localUser));
      }
    } else {
      if (Array.isArray(role)) {
        if (!role.includes(user.role)) {
          navigate(routes.login);
        }
      } else {
        if (user.role !== role) {
          navigate(routes.login);
        }
      }
    }
  }, [user])
}