import { useQuery } from 'react-query';
import ApiService from '../ApiService';
import { createFormData } from '../../helpers/createFormData';
import { useContext } from 'react';
import { Context } from '../../ContextProvider';
import { roles } from '../../const/roles';

export const useCheckRemainder = () => {
  const { setTaskMessage, user } = useContext(Context);

  return useQuery(
    'checkRemainder',
    data =>
      ApiService.request({
        url: '/checkRemainder.php',
        data: createFormData({
          date: new Date().toISOString(),
          ...data,
        }),
      }),
    {
      refetchInterval: 5000,
      cacheTime: 0,
      refetchIntervalInBackground: true,
      enabled: user?.role === roles.manager,
      onSuccess: data => data.text.length > 0 && setTaskMessage(data),
    }
  );
};
