import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminAddUser = () => {
  return useMutation(
    'useAdminAddUser',
    data =>
      ApiService.request({ url: '/adminAddUser.php', data }),
  );
};
