import styles from './styles.module.scss';
import { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDownWideShort, faArrowUpShortWide }  from '@fortawesome/free-solid-svg-icons';
import CardItem from '../CardItem';
import { useTheme } from '../../hooks/useThem';

library.add(faArrowDownWideShort, faArrowUpShortWide);

const statusColors = [styles.statusColor0, styles.statusColor1, styles.statusColor2, styles.statusColor3, styles.statusColor4, styles.statusColor5, styles.statusColor6, styles.statusColor7, styles.statusColor8];

export default function CardCategory({
                                       title,
                                       initOpen = false,
                                       status,
                                       leads,
                                       getLeads,
                                       setSelectedLead,
                                       cardOption = 1,
                                       canCardBeSelected = false,
}) {
  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : ''}`}>
      <div
        aria-controls="children"
        onClick={() => {
          setHeight(height === 0 ? 'auto' : 0);
          setIsOpen(true);
        }}
        className={`${styles.title} ${statusColors[status]} ${theme === 'dark' ? styles.dark : ''}`}
      >
        <div className={styles.flex}>
          { title }
          <div className={`${styles.icon} ${theme === 'dark' ? styles.dark : ''}`}>
            {height === 0 ?
              <FontAwesomeIcon icon="fa-solid fa-arrow-down-wide-short" /> :
              <FontAwesomeIcon icon="fa-solid fa-arrow-up-short-wide" />
            }
          </div>
        </div>
      </div>
      <AnimateHeight height={height} duration={1400}>
        <div id="children" className={`${styles.children} ${theme === 'dark' ? styles.dark : ''}`}>
          {
             isOpen && leads && leads.length > 0 && (leads.length > 100 ? height === 'auto' : true) && leads.map(lead => {
               return (
                 <CardItem
                   htmlId={`item${lead.id}`}
                   selectCallback={setSelectedLead}
                   key={lead.id}
                   lead={lead}
                   callbackAfterSubmit={getLeads}
                   option={cardOption}
                   canSelected={canCardBeSelected}
                 />
               );
             })
          }
        </div>
      </AnimateHeight>
    </div>
  );
}