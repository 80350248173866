import styles from '../../SelectedLeadsOptions/style.module.scss';
import { statuses } from '../../../const/statuses';
import { createFormData } from '../../../helpers/createFormData';
import Loader from '../../Loader';
import Modal from '../../Modal';
import { useAdminMoveLeadsToStatus } from '../../../api/hooks/useAdminMoveLeadsToStatus';
import { useContext, useState } from 'react';
import { Context } from '../../../ContextProvider';

export default function ModalChangeStatus({ isMoveToStatusOpened, parentSetter }) {
  const {
    selectedLeads,
    setNotifyWarn,
    setNotifySuc,
    updateParentResources,
  } = useContext(Context);

  const { mutate, isLoading } = useAdminMoveLeadsToStatus();

  const [ newStatus, setNewStatus ] = useState();

  return (
    <Modal title="Изменение статуса" isOpen={ isMoveToStatusOpened } parentSetter={ parentSetter }>
      <div className={ styles.relative }>
        <div>Перенести лиды в статус:</div>
        <select onChange={ e => setNewStatus(e.target.value) }>
          <option selected disabled>Не выбрано</option>
          {
            statuses.map((i, key) => (
              <option value={ key } key={ key }>{ i }</option>
            ))
          }
        </select>
        <button onClick={ () => {
          if (!newStatus) {
            return setNotifyWarn('Выберите новый статус');
          }
          return mutate(
            createFormData({ lids: selectedLeads, status: newStatus }),
            {
              onSuccess: () => {
                parentSetter(false);
                setNotifySuc('Лиды успешно обновлены');
                updateParentResources();
              }
            }
          )
        } }>
          Отправить
        </button>
        <Loader showing={ isLoading }/>
      </div>
    </Modal>
  );
}