import styles from '../styles.module.scss';
import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../ContextProvider';
import { useLogin } from '../api/hooks/useLogin';
import { homeRoute } from '../const/homeRoute';
import Loader from '../components/Loader';
import { useTheme } from '../hooks/useThem';

export default function Login() {
  const navigate = useNavigate();
  const { mutate, data, isLoading } = useLogin();
  const { setNotifyWarn, setUser, setNotifyErr } = useContext(Context);

  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');

  const sendLogin = useCallback(() => {
    if (login === '' || pass === '') {
      setNotifyWarn('Введите логин и пароль!');
      return;
    }

    const formData = new FormData();
    formData.append('login', login);
    formData.append('pass', pass);
    mutate(formData, {
      onError: () => setNotifyErr('Неверный логин или пароль'),
    });
  }, [pass, login, mutate]);

  useEffect(() => {
    if (data && data.role) {
      setUser(data);
      localStorage.setItem('user', JSON.stringify(data));
      navigate(homeRoute[data.role]);
    }
  }, [data]);

  const { theme } = useTheme();

  return (
    <div className={`${styles.loginWrap} ${theme === 'dark' ? styles.dark : ''}`} onKeyPress={e => e.key === 'Enter' && sendLogin()}>
      <div className={`${styles.loginForm} ${theme === 'dark' ? styles.dark : ''}`}>
        <input className={`${theme === 'dark' ? styles.dark : ''}`} onChange={e => setLogin(e.target.value)} placeholder='Логин' />
        <input className={`${theme === 'dark' ? styles.dark : ''}`} type='password' onChange={e => setPass(e.target.value)} placeholder='Пароль'/>
        <button onClick={sendLogin} className={`${styles.loginButton} ${theme === 'dark' ? styles.dark : ''}`}>Войти</button>
        <Loader showing={isLoading} />
      </div>
    </div>
  );
}