import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminFastSetLeads = () => {
  return useMutation(
    'useAdminFastSetLeads',
    data =>
      ApiService.request({ url: '/adminFastSetLeads.php', data }),
  );
};
