import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useGetAdmins = () => {
  return useMutation(
    'useGetAdmins',
    data =>
      ApiService.request({ url: '/getAdmins.php', data }),
  );
};
