import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useGetTasks = () => {
  return useMutation(
    'GetTasks',
    data =>
      ApiService.request({ url: '/getTasks.php', data }),
  );
};
