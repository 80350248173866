import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useUpdatePassword = () => {
  return useMutation(
    'updatePassword',
    data =>
      ApiService.request({ url: '/updatePassword.php', data }),
  );
};
