import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import SubmenuItem from '../SubmenuItem';
import styles from './styles.module.scss';
import { useTheme } from '../../../hooks/useThem';

export default function MenuItem({
  icon,
  text,
  route,
  withoutText,
  callback,
  stylesItem,
  stylesWrapper,
}) {
  const [showText, setShowText] = useState(!withoutText);
  const currentLocation = useLocation().pathname;

  useEffect(() => {
    setShowText(!withoutText);
  }, [withoutText]);

  const { theme } = useTheme();

  return (
    <Link
      style={stylesWrapper}
      onClick={() => callback && callback()}
      className={`${styles.wrapper} ${theme === 'dark' ? styles.dark : ''}`}
      to={route}>
      <div
        title={!showText && text}
        style={stylesItem}
        className={`${styles.item} ${
          currentLocation === route && styles.itemActive
        } ${theme === 'dark' ? styles.dark : ''}`}>
        <div className={`${styles.icon} ${withoutText && styles.withoutText} ${theme === 'dark' ? styles.dark : ''}`}>
          {icon}
        </div>
        {showText && (
          <div className={`${styles.textBlock} ${theme === 'dark' ? styles.dark : ''}`}>
            <Link
              className={`${styles.text} ${
                currentLocation === route && styles.textActive
              } ${theme === 'dark' ? styles.dark : ''}`}
              to={route}>
              {text}
            </Link>
          </div>
        )}
      </div>
    </Link>
  );
}
