import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminUpdateUser = () => {
  return useMutation(
    'useAdminUpdateUser',
    data =>
      ApiService.request({ url: '/adminUpdateUser.php', data }),
  );
};
