import { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { statuses } from '../../const/statuses';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { DateTimePicker } from 'react-datetime-picker';
import { useUpdateManagerLead } from '../../api/hooks/useUpdateManagerLead';
import { Context } from '../../ContextProvider';
import { createFormData } from '../../helpers/createFormData';
import Loader from '../Loader';
import { useTheme } from '../../hooks/useThem';
import { closedAndNotImplementReasons } from '../../const/closedAndNotImplementReasons';

export default function UpdateLeadForm({
                                         id,
                                         name,
                                         status,
                                         phone,
                                         tg,
                                         callbackAfterSubmit,
                                         managerId,
                                         containerStyles })
{
  const [newName, setNewName] = useState(name);
  const [newStatus, setNewStatus] = useState(status);
  const [newTg, setNewTg] = useState(tg);
  const [comment, setComment] = useState('');
  const [file, setFile] = useState();
  const [date, setDate] = useState(new Date());
  const [text, setText] = useState('');
  const [closedReason, setClosedReason] = useState('');
  const [updateLeadLoader, setUpdateLeadLoader] = useState(false);
  const [taskLoader, setTaskLoader] = useState(false);

  const { setNotifyErr, setNotifySuc, setNotifyWarn } = useContext(Context);

  const theme = useTheme();

  const { mutate } = useUpdateManagerLead();

  const submitUpdateLead = useCallback(() => {
    if (newStatus === '8' && closedReason === '') {
      setNotifyWarn('Вы не выбрали причину закрытия работы с лидом');
      return;
    }
    if (newStatus !== '8' && newStatus !== '7' && comment === '') {
      setNotifyWarn('Поле комментарий обязательно');
      return;
    }
    let sendComment = comment;
    if (newStatus === '8') {
      sendComment += `Закрыто и не реализовано (причина):\r\n${closedReason}`;
    }
    setUpdateLeadLoader(true);
    mutate(
      {
        data: createFormData({
          file,
          status: newStatus,
          id,
          tg: newTg,
          statusFrom: status,
          comment: sendComment,
          name: newName,
          phone,
          managerId
        }),
        params: { method: 'updateLead' }
      },
      {
        onSuccess: d => {
          if (d.text === 'incorrectFile') {
            setNotifyErr('Прикплен недопустимый файл для загрузки');
          }
          if (d.text === 'updateLead') {
            setNotifySuc('Лид успешно был обновлен');
            callbackAfterSubmit();
          } else {
            setNotifyWarn('Произошла ошибка во время отправки запроса');
          }
          setUpdateLeadLoader(false);
        }
      }
    );
  }, [
    newName,
    newStatus,
    comment,
    file,
    callbackAfterSubmit,
    setNotifyErr,
    setNotifySuc,
    closedReason
  ]);

  const submitSetTask = useCallback(() => {
    if (date === null) {
      setNotifyWarn('Необходимо обязательно установить задачу!');
      return;
    }
    if (text === '' || date <= new Date()) {
      setNotifyWarn('Заполните дату и текст задачи корректно (дата не может быть в прошлом)!');
      return;
    }
    setTaskLoader(true);
    mutate(
      {
        data: createFormData({
          id,
          text,
          date: date.toISOString(),
          phone,
          tg,
          managerId
        }),
        params: { method: 'setTask' }
      },
      {
        onSuccess: d => {
          if (d.text === 'setTask') {
            setNotifySuc('Задача успешно установлена');
            callbackAfterSubmit();
          } else {
            setNotifyWarn('Произошла ошибка во время отправки запроса');
          }
          setTaskLoader(false);
        }
      }
    );
  }, [
    date,
    text,
    callbackAfterSubmit,
    setNotifyErr,
    setNotifySuc,
  ]);

  return (
    <div className={styles.flex}>
      <div style={containerStyles} className={styles.container}>

        <div className={`${styles.title} ${theme === 'dark' ? styles.dark : ''}`}>
          Обновить лид
        </div>

        <div className={styles.row}>
          <p>Телеграм лида</p>
          <input onChange={e => setNewTg(e.target.value)} value={newTg} placeholder='Ник или номер телефона'/>
        </div>

        <div className={styles.row}>
          <p>Обновить имя лида</p>
          <input onChange={e => setNewName(e.target.value)} value={newName} placeholder='Имя лида'/>
        </div>

        <div className={styles.row}>
          <p>Перевести в статус</p>
          <select onChange={e => setNewStatus(e.target.value)}>
            <option disabled selected>Выберите статус</option>
            {
              statuses.map((name, id) => {
                if (id == status) {
                  return <option selected value={id}>{name}</option>
                }
                return <option value={id}>{name}</option>
              })
            }
          </select>
        </div>

        { newStatus !== '7' && newStatus !== '8' &&
        <div className={styles.row}>
          <textarea onChange={e => setComment(e.target.value)} placeholder='Комментарий, заметка'>{comment}</textarea>
        </div>
        }

        <div className={styles.row}>
          <p>Можете прикрепить файл касающийся разговора</p>
          <input type='file' onChange={e => setFile(e.target.files[0])}/>
        </div>

        { newStatus === '8' &&
        <div className={styles.row}>
          <p>Закрыто и не реализовано (причины):</p>
          <select onChange={e => setClosedReason(e.target.value)}>
            <option disabled selected>Выберите причину</option>
            {
              closedAndNotImplementReasons.map((name, id) => {
                return <option key={id} value={name}>{name}</option>
              })
            }
          </select>
        </div>
        }

        <button onClick={() => submitUpdateLead()}>Обновить</button>

        <Loader showing={updateLeadLoader} />
      </div>

      {
        newStatus !== '7' && newStatus !== '8' &&
        <div style={containerStyles} className={styles.container}>
          <div className={`${styles.title} ${theme === 'dark' ? styles.dark : ''}`}>
            Установить задачу
          </div>

          <div className={ styles.row }>
            <p>Дата и время задачи:</p>
            <DateTimePicker showLeadingZeros={ true } value={ date } onChange={ setDate }/>
          </div>

          <div className={styles.row}>
            <textarea onChange={e => setText(e.target.value)} placeholder='Текст напоминания'>{text}</textarea>
          </div>

          <button onClick={() => submitSetTask()}>Установить</button>

          <Loader showing={taskLoader} />
        </div>
      }
    </div>
  );
}