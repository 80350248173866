import Wrapper from '../../components/Wrapper';
import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import ContentBox from '../../components/ContentBox';
import styles from '../../styles.module.scss';
import { DatePicker } from 'react-date-picker';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../ContextProvider';
import { useGetManagers } from '../../api/hooks/useGetManagers';
import { normalizeManagers } from '../../helpers/normalizeManagers';
import Loader from '../../components/Loader';
import { useAdminGetManagerHistory } from '../../api/hooks/useAdminGetManagerHistory';
import { createFormData } from '../../helpers/createFormData';
import { statuses } from '../../const/statuses';
import stylesForStatus from '../../components/OpenLead/styles.module.scss';
import OpenLead from '../../components/OpenLead';
import { useAdminGetLoginLog } from '../../api/hooks/useAdminGetLoginLog';
import { formatDate } from '../../helpers/formatDate';

export default function LoginLog() {
  useCheckRole(roles.admin);

  const { managers, setManagers, setNotifyWarn, setNotifySuc } = useContext(Context);

  const {
    mutate: mutateGetManagers,
    isLoading: isLoadingGetManagers
  } = useGetManagers();

  const { mutate, isLoading } = useAdminGetLoginLog();

  const [startDate, setStartDate] = useState(new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 1
  ));
  const [endDate, setEndDate] = useState(new Date());
  const [managerId, setManagerId] = useState();
  const [orders, setOrders] = useState();

  const getManagers = useCallback(() => {
    mutateGetManagers({}, {
      onSuccess: d => setManagers(normalizeManagers(d)),
    });
  }, [mutateGetManagers]);

  useEffect(() => {
    getManagers();
  }, [])

  const getOrders = useCallback(() => {
    const data = {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    };
    if (managerId) {
      data.managerId = managerId;
    }
    mutate(createFormData(data), {
      onSuccess: d => setOrders(d),
    })
  }, [mutate, startDate, endDate, managerId]);

  return (
    <Wrapper>
      <ContentBox>
        <h2>Выберите менеджера и период даты, чтобы загрузить историю его входа</h2>
        <div className={styles.marginTop1Rem}>
          С
          <DatePicker value={startDate} showLeadingZeros={true} onChange={setStartDate} />
        </div>
        <div className={styles.marginTop1Rem}>
          По
          <DatePicker value={endDate} showLeadingZeros={true} onChange={setEndDate} />
        </div>
        <div className={styles.marginTop1Rem}>
          Выберите менеджера
          <select onChange={e => {
            const value = e.target.value;
            if (value === 'all') {
              return setManagerId(undefined);
            }
            return setManagerId(value);
          }}>
            <option value={'all'} selected>Все</option>
            {
              Object.keys(managers).map(key =>
                <option selected={managers[key].id === managerId} key={key} value={key}>{managers[key].login}</option>
              )
            }
          </select>
        </div>
        <button onClick={() => {
          if (!startDate || !endDate) {
            return setNotifyWarn('Даты не выбраны');
          }
          getOrders();
        }}>
          Загрузить
        </button>
        <Loader showing={isLoadingGetManagers} />
      </ContentBox>

      <ContentBox>
        <div className={styles.row}>
          <div className={styles.item}>
            <b>Дата</b>
          </div>
          <div className={`${styles.item}`}>
            <b>Менеджер</b>
          </div>
        </div>
        {orders?.length < 1 && <p>Записи отсутсвуют</p>}
        {
          orders?.length > 0 &&
          orders?.map(({id, date, manager: { login }}) => (
            <div key={id} className={styles.row}>
              <div className={styles.item}>
                {formatDate(date)}
              </div>
              <div className={`${styles.item}`}>
                {login}
              </div>
            </div>
          ))
        }

        <Loader showing={isLoading} />
      </ContentBox>
    </Wrapper>
  );
}