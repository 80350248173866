import { useMutation } from 'react-query';
import ApiService from '../ApiService';
import { useContext, useMemo } from 'react';
import { Context } from '../../ContextProvider';
import { roles } from '../../const/roles';

export const useGetManagerLeadByIdOrPhone = () => {
  const { user: { role } } = useContext(Context);

  const url = useMemo(() => {
    if (role === roles.admin) {
      return '/getAdminLeadByIdOrPhone.php';
    }
    return '/getManagerLeadByIdOrPhone.php';
  }, [role]);

  return useMutation(
    'useGetManagerLeadByIdOrPhone',
    data =>
      ApiService.request({ url, data }),
  );
};
