import styles from './styles.module.scss';
import styles2 from '../../styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import {
  faHashtag,
  faUserSecret,
  faFileSignature
}  from '@fortawesome/free-solid-svg-icons';
import { Menu, useContextMenu, Item } from 'react-contexify';
import 'react-contexify/ReactContexify.css';
import { useContext, useState } from 'react';
import { useGetAdminLeadsStat } from '../../api/hooks/useGetAdminLeadsStat';
import Modal from '../Modal';
import Loader from '../Loader';
import { Context } from '../../ContextProvider';
import { useAdminFastSetLeads } from '../../api/hooks/useAdminFastSetLeads';
import { createFormData } from '../../helpers/createFormData';
import { statuses } from '../../const/statuses';
import { useAdminMoveLeadsFromManager } from '../../api/hooks/useAdminMoveLeadsFromManager';
import { useAdminUpdateUser } from '../../api/hooks/useAdminUpdateUser';
import { useAdminResetUserPassword } from '../../api/hooks/useAdminResetUserPassword';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { useAdminBlockUser } from '../../api/hooks/useAdminBlockUser';
import { useAdminUnBlockUser } from '../../api/hooks/useAdminUnBlockUser';
import { useTheme } from '../../hooks/useThem';
import { useNavigate } from "react-router-dom";
import { routes } from '../../const/routes';

library.add(
  faHashtag,
  faUserSecret,
  faTelegram,
  faFileSignature
);

export default function ManagerCard({
  manager,
  type = 1,
}) {
  const { id, login, fio, tg } = manager;

  const { setNotifyWarn, setNotifySuc, managers, updateParentResources, setOpenedManager } = useContext(Context);

  const [ isOpenedSetLeads, setIsOpenedSetLeads ] = useState(false);
  const [ isOpenedMoveLeads, setIsOpenedMoveLeads ] = useState(false);
  const [ isOpenedEditing, setIsOpenedEditing ] = useState(false);
  const [ isOpeningNewPass, setIsOpeningNewPass ] = useState(false);
  const [ stat, setStat ] = useState();
  const [ countSettingLeads, setCountSettingLeads ] = useState(0);
  const [ selectedStatus, setSelectedStatus ] = useState();
  const [ managerId, setManagerId ] = useState();
  const [ resetStatus, setResetStatus ] = useState();
  const [ newLogin, setNewLogin ] = useState(login);
  const [ newFio, setNewFio ] = useState(fio);
  const [ newTg, setNewTg ] = useState(tg);
  const [ newPass, setNewPass ] = useState('Не сгенирировался еще');

  const { mutate: mutateGetStat, isLoading: isLoadingGetStat } = useGetAdminLeadsStat();
  const { mutate: mutateSetLeads, isLoading: isLoadingSetLeads } = useAdminFastSetLeads();
  const { mutate: mutateMove, isLoading: isLoadingMove } = useAdminMoveLeadsFromManager();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useAdminUpdateUser();
  const { mutate: mutatePass, isLoading: isLoadingPass } = useAdminResetUserPassword();
  const { mutate: mutateBlock, isLoading: isLoadingBlock } = useAdminBlockUser();
  const { mutate: mutateUnBlock, isLoading: isLoadingUnBlock } = useAdminUnBlockUser();

  const { show } = useContextMenu();

  const { theme } = useTheme();

  const navigate = useNavigate();

  return (
    <div className={ `${ styles.card }` }>
      <div title='ID менеджера' className={ styles.row }>
        <FontAwesomeIcon icon="fa-solid fa-hashtag"/>
        { id }
      </div>
      <div title='Логин менеджера' className={ styles.row }>
        <FontAwesomeIcon icon="fa-solid fa-user-secret"/>
        { login }
      </div>
      <div title='ФИО' className={ styles.row }>
        <FontAwesomeIcon icon="fa-solid fa-file-signature"/>
        { fio }
      </div>
      <div title='Телеграм' className={ styles.row }>
        <FontAwesomeIcon icon="fa-brands fa-telegram"/>
        { tg }
      </div>

      {/*Buttons: */ }
      {
        type < 5 &&
        <>
          <button className={ `${styles.defaultClear} ${theme === 'dark' ? styles.dark : ''}` } onClick={ e => show({ id, event: e }) }>
            Опции
          </button>
          <button
            className={`${theme === 'dark' ? styles.dark : ''}`}
            onClick={() => {
              setOpenedManager(id);
              navigate(routes.adminLidsBase);
            }}
          >
            Открыть лиды
          </button>

          <Menu id={ id } animation="scale">
            {
              type !== 0 &&
              <Item
                onClick={ () => {
                  setIsOpenedSetLeads(true);
                  mutateGetStat({}, { onSuccess: d => setStat(d) });
                } }
              >
                Быстро назначить лиды
              </Item>
            }
            <Item
              onClick={ () => {
                setIsOpenedMoveLeads(true);
              } }
            >
              Массовый перенос лидов
            </Item>
            {
              type !== 0 &&
              <Item onClick={ () => setIsOpenedEditing(true) }>
                Редактировать
              </Item>
            }
            {
              type !== 0 &&
              <Item
                onClick={() => {
                  mutateBlock(createFormData({ id }), {
                    onSuccess: () => {
                      setNotifySuc('Пользователь был успешно заблокирован');
                      updateParentResources();
                    }
                  })
                }}>
                Деактивировать
              </Item>
            }
            {
              type === 0 &&
              <Item
                onClick={() => {
                  mutateUnBlock(createFormData({ id }), {
                    onSuccess: () => {
                      setNotifySuc('Пользователь был успешно разблокирован');
                      updateParentResources();
                    }
                  })
                }}>
                Активировать
              </Item>
            }
            {
              type !== 0 &&
              <Item
                onClick={ () => {
                  mutatePass(createFormData({ id }), {
                    onSuccess: d => {
                      setNewPass(d);
                      setIsOpeningNewPass(true);
                    }
                  })
                } }>
                Сбросить пароль
              </Item>
            }
          </Menu>
        </>
      }

      <Modal title='Быстро назначить лиды' parentSetter={ setIsOpenedSetLeads } isOpen={ isOpenedSetLeads } closedOnOutside>
        <span className={ styles2.label3 }>Всего неназначено лидов: { stat?.notAppointedCount }</span>
        <input className={`${theme === 'dark' ? styles.dark : ''}`} placeholder={ `Сколько лидов назначить на менеджера ${ login }?` } type='number' onChange={ e => setCountSettingLeads(e.target.value) }/>
        <button className={`${styles.button} ${theme === 'dark' ? styles.dark : ''}`} onClick={ () => {
          if (countSettingLeads < 1 || countSettingLeads > stat?.notAppointedCount) {
            return setNotifyWarn('Введено неверное количество');
          }
          mutateSetLeads(createFormData({ id, field: countSettingLeads, manager: login }), {
            onSuccess: d => {
              setNotifySuc(d);
              setIsOpenedSetLeads(false);
            }
          });
        } }>
          Назначить
        </button>
        <Loader showing={ isLoadingGetStat || isLoadingSetLeads }/>
      </Modal>

      <Modal title='Массовый перенос лидов' parentSetter={ setIsOpenedMoveLeads } isOpen={ isOpenedMoveLeads } closedOnOutside>
        <div className={ styles2.marginTop1Rem }>
          Перенести
          <select className={`${theme === 'dark' ? styles.dark : ''}`} onChange={ e => setSelectedStatus(e.target.value) }>
            <option selected disabled>Не выбрано</option>
            <option value='all'>Все лиды</option>
            {
              statuses.map((i, key) => (
                <option value={ key } key={ key }>{ i }</option>
              ))
            }
          </select>
        </div>
        <div className={ styles2.marginTop1Rem }>
          на
          <select className={`${theme === 'dark' ? styles.dark : ''}`} onChange={ e => setManagerId(e.target.value) }>
            <option selected disabled>Не выбрано</option>
            <option value={ 'no' }>Перенести в нераспределенные</option>
            {
              Object.keys(managers).map(key =>
                <option key={ key } value={ key }>{ managers[key].login }</option>
              )
            }
          </select>
        </div>
        <div className={ styles2.marginTop1rem }>
          <input className={ `${styles2.wFC} ${theme === 'dark' ? styles.dark : ''}` } onChange={ e => setResetStatus(e.target.value) } type='checkbox' value={ true } id='check'/>
          <label htmlFor='check'>Сбросить статус до "новый лид"</label>
        </div>
        <button className={`${styles.button} ${theme === 'dark' ? styles.dark : ''}`} onClick={ () => {
          if (!managerId || !selectedStatus) {
            return setNotifyWarn('Выберите корректные значения');
          }
          let data = { status: selectedStatus, target: managerId, id };
          if (resetStatus) {
            data = { resetStatus, ...data };
          }
          mutateMove(createFormData(data), {
            onSuccess: d => {
              setNotifySuc(d);
              setIsOpenedMoveLeads(false);
              updateParentResources();
            }
          });
        } }>
          Выполнить перенос
        </button>
        <Loader showing={ isLoadingMove }/>
      </Modal>

      <Modal title={ `Редактирование юзера ${ login }` } isOpen={ isOpenedEditing } parentSetter={ setIsOpenedEditing }>
        <div className={ styles2.marginTop1Rem }>
          Логин
          <input className={`${theme === 'dark' ? styles.dark : ''}`} defaultValue={ login } onChange={ e => setNewLogin(e.target.value) }/>
        </div>
        <div className={ styles2.marginTop1Rem }>
          ФИО
          <input className={`${theme === 'dark' ? styles.dark : ''}`} defaultValue={ fio } onChange={ e => setNewFio(e.target.value) }/>
        </div>
        <div className={ styles2.marginTop1Rem }>
          Телеграм
          <input className={`${theme === 'dark' ? styles.dark : ''}`} defaultValue={ tg } onChange={ e => setNewTg(e.target.value) }/>
        </div>
        <button className={`${styles.button} ${theme === 'dark' ? styles.dark : ''}`} onClick={ () => {
          if (newLogin.length < 5) {
            return setNotifyWarn('Слишком короткий логин');
          }
          mutateUpdate(createFormData({ id, login: newLogin, fio: newFio, tg: newTg }), {
            onSuccess: () => {
              setNotifySuc('Пользователь был успешно обновлен');
              setIsOpenedEditing(false);
              updateParentResources();
            }
          });
        } }>
          Сохранить
        </button>
        <Loader showing={ isLoadingUpdate }/>
      </Modal>

      <Modal title='Новый пароль' parentSetter={ setIsOpeningNewPass } isOpen={ isOpeningNewPass }>
        Пароль { login } был обновлен. Теперь он:
        <div>{ newPass }</div>
        <CopyToClipboard text={ newPass } onCopy={ () => setNotifySuc('Пароль успешно был скопирован') }>
          <button>Скопировать</button>
        </CopyToClipboard>
        <Loader showing={ isLoadingPass }/>
      </Modal>

      <Loader showing={isLoadingBlock || isLoadingUnBlock} />
    </div>
  );
}