import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminDeleteLeads = () => {
  return useMutation(
    'useAdminDeleteLeads',
    data =>
      ApiService.request({ url: '/adminDeleteLeads.php', data }),
  );
};
