import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import IncorrectRoute from './pages/IncorrectRoute';
import Login from './pages/Login';
import ContextProvider from './ContextProvider';
import { routes } from './const/routes';
import Lids from './pages/manager/Lids';
import { NotificationContainer } from 'react-notifications';
import Tasks from './pages/manager/Tasks';
import Settings from './pages/manager/Settings';
import AddLids from './pages/admin/AddLids';
import LidsBase from './pages/admin/LidsBase';
import Users from './pages/admin/Users';
import WorkLog from './pages/admin/WorkLog';
import LoginLog from './pages/admin/LoginLog';
import TgBot from './pages/admin/TgBot';
import SupportBot from './pages/admin/SupportBot';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router basename='/cabinet'>
        <ContextProvider>
          <Routes>
            <Route path={routes.managerLids} element={<Lids />} />
            <Route path={routes.managerTasks} element={<Tasks />} />

            <Route path={routes.adminAddLids} element={<AddLids />} />
            <Route path={routes.adminLidsBase} element={<LidsBase />} />
            <Route path={routes.adminUsers} element={<Users />} />
            <Route path={routes.adminUserHistory} element={<WorkLog />} />
            <Route path={routes.adminLoginLog} element={<LoginLog />} />
            <Route path={routes.adminTgBot} element={<TgBot />} />
            <Route path={routes.adminSupportBot} element={<SupportBot />} />

            <Route path={routes.managerSettings} element={<Settings />} />
            <Route exact path={routes.login} element={<Login />} />
            <Route path="*" element={<IncorrectRoute />} />
          </Routes>
        </ContextProvider>
      </Router>
      <NotificationContainer />
    </QueryClientProvider>
  </React.StrictMode>,
);
