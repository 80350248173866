import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useGetManagerLeadTasks = () => {
  return useMutation(
    'GetManagerLeadTasks',
    data =>
      ApiService.request({ url: '/getManagerLeadTasks.php', data }),
  );
};
