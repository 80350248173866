import ContentBox from '../../ContentBox';
import { useEffect, useState } from 'react';
import { Button } from '@nextui-org/react';
import { useAdminSupportBotGetShortStat } from '../../../api/hooks/useAdminSupportBotGetShortStat';
import { createFormData } from '../../../helpers/createFormData';
import { Chart } from 'react-google-charts';
import Loader from '../../Loader';

export default function TgSupportStat() {
  const legenda = ['Менеджеры', 'Нажал старт работа', 'Взял новых лидов'];
  const [days, setDays] = useState(1);
  const [stat, setStat] = useState([]);

  const { isLoading, mutate } = useAdminSupportBotGetShortStat();

  useEffect(() => {
    mutate(
      createFormData({ days }),
      {
        onSuccess: data => setStat([legenda, ...data]),
      }
    );
  }, [days]);

  useEffect(() => {
    console.log('stat', stat);
  }, [stat]);

  return (
    <ContentBox>
      <h2>Общая статистика</h2>
      <p>Статистика за {days} дней</p>
      <Button onPress={() => setDays(1)}>За 1 день</Button>
      <Button onPress={() => setDays(7)}>За 7 дней</Button>
      <Button onPress={() => setDays(30)}>За 30 дней</Button>

      <Chart
        chartType="Bar"
        width="100%"
        height="400px"
        data={stat}
      />

      <Loader showing={isLoading} />
    </ContentBox>
  );
}