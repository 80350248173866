import { useCallback, useContext, useState } from 'react';
import styles from './styles.module.scss';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { useUpdateManagerLead } from '../../api/hooks/useUpdateManagerLead';
import { Context } from '../../ContextProvider';
import { createFormData } from '../../helpers/createFormData';
import Loader from '../Loader';
import { useGetManagerLeadByIdOrPhone } from '../../api/hooks/useGetManagerLeadByIdOrPhone';
import { useTheme } from '../../hooks/useThem';

export default function FindLeadForm({ setSelectLead, modalParentSetter }) {
  const [id, setId] = useState('');
  const [phone, setPhone] = useState('');

  const { setNotifySuc, setNotifyWarn } = useContext(Context);

  const { mutate, isLoading } = useGetManagerLeadByIdOrPhone();

  const submit = useCallback(() => {
    if (id.length < 1 && phone.length < 5) {
      setNotifyWarn('Введите номер телефона или id лида!');
      return;
    }
    mutate(
      createFormData({
        id,
        phone: phone.replace('+', '')
      }),
      {
        onSuccess: d => {
          setSelectLead(d);
          modalParentSetter(false);
          setNotifySuc('Лид успешно открыт');
        },
        onError: () => {
          setNotifyWarn('Лид не найден');
        }
      }
    );
  }, [phone, id, mutate]);

  return (
    <div className={styles.container}>

      <div className={styles.row}>
        <p>Введите номер телефона</p>
        <input onChange={e => setPhone(e.target.value)} value={phone} placeholder='Номер телефона лида'/>
      </div>

      <div className={styles.row}>
        <p>или id лида</p>
        <input onChange={e => setId(e.target.value)} value={id} placeholder='Id лида'/>
      </div>

      <button onClick={() => submit()}>Найти</button>

      <Loader showing={isLoading} />
    </div>
  );
}