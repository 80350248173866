import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useLogin = () => {
  return useMutation(
    'login',
    data =>
      ApiService.request({ url: '/login.php', data }),
  );
};
