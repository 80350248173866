import CardItem from '../CardItem';
import styles from './styles.module.scss';
import ContentBox from '../ContentBox';
import { useGetManagerLeadHistory } from '../../api/hooks/useGetManagerLeadHistory';
import Loader from '../Loader';
import { useCallback, useContext, useEffect, useState } from 'react';
import { createFormData } from '../../helpers/createFormData';
import { statuses } from '../../const/statuses';
import { useGetManagerLeadTasks } from '../../api/hooks/useGetManagerLeadTasks';
import UpdateLeadForm from '../UpdateLeadForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSquareCheck
} from '@fortawesome/free-solid-svg-icons';
import { Context } from '../../ContextProvider';
import { useTheme } from '../../hooks/useThem';
import { formatDate } from '../../helpers/formatDate';

library.add(faSquareCheck);

const statusColors = [styles.statusColor0, styles.statusColor1, styles.statusColor2, styles.statusColor3, styles.statusColor4, styles.statusColor5, styles.statusColor6, styles.statusColor7, styles.statusColor8];

export default function OpenLead({ lead, getLeadsCallback, cardOption = 1 }) {
  const {mutate: leadHistoryMutation, isLoading: leadHistoryIsLoading} = useGetManagerLeadHistory();
  const {mutate: leadTasksMutate, isLoading: leadTasksIsLoading} = useGetManagerLeadTasks();

  const { setSelectedLeads } = useContext(Context);

  const {
    id,
    name,
    status,
    phone,
    tg,
    managerId,
  } = lead;

  useEffect(() => {
    setSelectedLeads([]);
  }, []);

  const [history, setHistory] = useState([]);
  const [tasks, setTasks] = useState([]);

  const loadHistory = useCallback(() => {
    leadHistoryMutation(createFormData({ id }), { onSuccess: d => setHistory(d) });
  }, [lead.id, setHistory]);

  const loadTasks = useCallback(() => {
    leadTasksMutate(createFormData({ id }), { onSuccess: d => setTasks(d) });
  }, [lead.id, setTasks]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    loadHistory();
  }, [loadHistory]);

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  const { theme } = useTheme();

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : ''}`}>
      <CardItem option={cardOption} lead={lead} />

      <ContentBox>
        <h2>История лида</h2>
        {
          history.length === 0 && <p>История пустая</p>
        }
        {
          history.length > 0 &&
          history.map(i => (
            <div key={i.id} className={styles.row}>
              <div className={styles.item}>
                <div>
                  Из статуса<br/>
                  <span className={statusColors[i.statusFrom]}>{statuses[i.statusFrom]}</span>
                </div>
              </div>
              <div className={styles.item}>
                <div>
                  В статус<br/>
                  <span className={statusColors[i.statusTo]}>{statuses[i.statusTo]}</span>
                </div>
              </div>
              <div className={styles.item}>
                <div>
                  Менеджер<br/>
                  {i.manager}
                </div>
              </div>
              <div className={`${styles.item} ${styles.comment}`}>
                {i.comment}
              </div>
              <div className={styles.item}>
                <a href={i.fileLink} target='_blank'>Открыть файл</a>
              </div>
              <div className={styles.item}>
                {formatDate(i.date)} <br/>
                (наше время)
              </div>
            </div>
          ))
        }
        <Loader showing={leadHistoryIsLoading} />
      </ContentBox>

      <ContentBox>
        <h2>Задачи</h2>
        {
          tasks.length === 0 && <p>Еще не было ни одной задачи</p>
        }
        {
          tasks.length > 0 &&
          tasks.map(i => (
            <div key={i.id} className={styles.row}>
              <div className={styles.item}>
                {i.isExpired == 1 &&
                <div title="Выполненная задача" className={`${styles.done}`}>
                  <FontAwesomeIcon icon="fa-solid fa-square-check" />
                </div>
                }
                {formatDate(i.date)} <br/>
                (наше время)
              </div>
              <div className={`${styles.item} ${styles.comment}`}>
                {i.text}
              </div>
            </div>
          ))
        }
        <Loader showing={leadTasksIsLoading} />
      </ContentBox>

      <ContentBox>
        <UpdateLeadForm
          name={name}
          status={status}
          id={id}
          phone={phone}
          tg={tg}
          callbackAfterSubmit={() => {
            loadTasks();
            loadHistory();
            if (getLeadsCallback) {
              getLeadsCallback();
            }
          }}
          managerId={managerId}
        />
      </ContentBox>
    </div>
  );
}