import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faIdCardClip, faCertificate, faCalendar, faHashtag, faTriangleExclamation, faUserSecret, faTags, faEllipsisVertical }  from '@fortawesome/free-solid-svg-icons';
import { statuses } from '../../const/statuses';
import { useContext, useEffect, useMemo, useState } from 'react';
import Modal from '../Modal';
import UpdateLeadForm from '../UpdateLeadForm';
import { Context } from '../../ContextProvider';
import { useTheme } from '../../hooks/useThem';
import { formatDate } from '../../helpers/formatDate';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import ModalChangeStatus from '../admin/ModalChangeStatus';
import ModalChangeManager from '../admin/ModalChangeManager';
import ModalDeleteLead from '../admin/ModalDeleteLead';


library.add(faWhatsapp, faIdCardClip, faCertificate, faCalendar, faHashtag, faTriangleExclamation, faUserSecret, faTags, faEllipsisVertical, faTelegram);

export default function CardItem({
                                   lead,
                                   selectCallback,
                                   callbackAfterSubmit,
                                   option = 1,
                                   canSelected = false,
                                   htmlId,
}) {
  const {
    managers,
    setSelectedLeads,
    selectedLeads,
    setOpenedManager
  } = useContext(Context);

  const { id, name, phone, status, lastTuch, isExpired, managerId, tag, tg } = lead;
  const [fastUpdateModalIsOpen, setFastUpdateModalIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const [ isMoveToStatusOpened, setIsMoveToStatusOpened ] = useState(false);
  const [ isMoveToManagerOpened, setIsMoveToManagerOpened ] = useState(false);
  const [ isDeleteOpened, setIsDeleteOpened ] = useState(false);

  const managerName = useMemo(() => {
    const int = parseInt(managerId);
    if (int === 0) {
      return 'Менеджера нет';
    }
    return managers[int]?.login;
  },[managerId]);

  const hasManager = useMemo(() => {
    const int = parseInt(managerId);
    return int !== 0;
  }, [managerId])

  useEffect(() => {
    if (selectedLeads.length === 0) {
      setIsSelected(false);
    }
  }, [selectedLeads])

  const { theme } = useTheme();

  return (
    <div
      id={htmlId || id}
      className={`${styles.card} ${isSelected && styles.selectedCard} ${theme === 'dark' ? styles.dark : ''}`}
      onClick={() => {
        if (canSelected) {
          setSelectedLeads(prev => {
            const res = [...prev];
            const index = res.indexOf(id);
            if (index === -1) {
              setIsSelected(true);
              res.push(id);
              return res;
            } else {
              setIsSelected(false);
              res.splice(index, 1);
              return res;
            }
          })
        }
      }}>
      {
        option === 2 &&
        <Dropdown>
          <DropdownTrigger>
            <Button
              className={styles.contextMenuBtn}
              variant="flat"
            >
              <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem
              onPress={() => {
                setSelectedLeads([id]);
                setIsMoveToStatusOpened(true);
              }
            }>
              Перенести в статус
            </DropdownItem>
            <DropdownItem
              onPress={() => {
                setSelectedLeads([id]);
                setIsMoveToManagerOpened(true);
              }
            }>
              Назначить менеджера
            </DropdownItem>
            <DropdownItem
              className="text-danger"
              color="danger"
              onPress={() => {
                setIsDeleteOpened(true);
                setSelectedLeads([id]);
              }
            }>
              Удалить лид
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      }
      {
        isExpired && status !== '0' &&
        <div className={`${styles.row} ${styles.alert} ${theme === 'dark' ? styles.dark : ''}`}>
          <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" />
          Пропущена задача!
        </div>
      }
      <a
        href={`https://wa.me/${phone.replace(/ /g, '')}`}
        target='_blank'
        className={`${styles.phone} ${theme === 'dark' ? styles.dark : ''}`}
      >
        <FontAwesomeIcon icon="fa-brands fa-whatsapp" />
        { phone }
      </a>
      {
        tg?.length > 1 &&
        <a
          href={`https://t.me/${tg?.trim()}`}
          target='_blank'
          className={`${styles.phone} ${theme === 'dark' ? styles.dark : ''}`}
        >
          <FontAwesomeIcon icon="fa-brands fa-telegram" />
          { tg }
        </a>
      }
      <div title='ID лида' className={styles.row}>
        <FontAwesomeIcon icon="fa-solid fa-hashtag" />
        {id}
      </div>
      <div title='Имя лида' className={styles.row}>
        <FontAwesomeIcon icon="fa-solid fa-id-card-clip" />
        {name.length > 0 ? name : 'Имя не заполнено'}
      </div>
      <div title='Статус' className={styles.row}>
        <FontAwesomeIcon icon="fa-solid fa-certificate" />
        {statuses[status]}
      </div>
      {
        status !== '0' &&
        <div title='Дата последнего вазимодействия с лидом' className={styles.row}>
          <FontAwesomeIcon icon="fa-solid fa-calendar" />
          {formatDate(lastTuch)}
        </div>
      }

      {
        option === 2 &&
        <div title='Менеджер' className={styles.row}>
          <FontAwesomeIcon icon="fa-solid fa-user-secret" />
          {managerName}
        </div>
      }

      <div title='Тег' className={styles.row}>
        <FontAwesomeIcon icon="fa-solid fa-tags" />
        {tag}
      </div>

      {/*Buttons: */}
      <div className={styles.row}>
        <button
          onClick={() => setFastUpdateModalIsOpen(true)}
          className={`${styles.defaultClear} ${theme === 'dark' ? styles.dark : ''}`}>
          Быстрое обновление
        </button>
      </div>

      {
        option === 2 && hasManager &&
        <div className={styles.row}>
          <button
            onClick={(e) => {
              setOpenedManager(managerId);
              e.stopPropagation();
            }}
            className={`${styles.defaultClear} ${theme === 'dark' ? styles.dark : ''}`}>
            Лиды менеджера
          </button>
        </div>
      }
      {
        selectCallback &&
        <div className={styles.row}>
          <button className={`${styles.button} ${theme === 'dark' ? styles.dark : ''}`} onClick={() => selectCallback(lead)}>Открыть карточку</button>
        </div>
      }

      <ModalChangeStatus parentSetter={setIsMoveToStatusOpened} isMoveToStatusOpened={isMoveToStatusOpened} />

      <ModalChangeManager setIsMoveToManagerOpened={setIsMoveToManagerOpened} isMoveToManagerOpened={isMoveToManagerOpened} />

      <ModalDeleteLead isDeleteOpened={ isDeleteOpened } setIsDeleteOpened={ setIsDeleteOpened } />

      <Modal
        modalStyles={{ width: '80vw' }}
        title={'Быстрое обновление лида'}
        children={
          <UpdateLeadForm
            containerStyles={{ height: '70vh', marginTop: '1rem' }}
            phone={phone}
            tg={tg}
            id={id}
            name={name}
            status={status}
            callbackAfterSubmit={() => {
              //setFastUpdateModalIsOpen(false);
              return callbackAfterSubmit();
            }}
          />
        }
        isOpen={fastUpdateModalIsOpen}
        parentSetter={setFastUpdateModalIsOpen}
      />
    </div>
  );
}