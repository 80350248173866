import { statuses } from '../../const/statuses';

export default function LeadsSelect({ setSelected }) {
  return (
    <select onChange={ e => setSelected(e.target.value) }>
      <option selected disabled>Выберите статус</option>
      {
        statuses.map((i, key) => (
          <option value={ key } key={ key }>{ i }</option>
        ))
      }
    </select>
  );
}