import Wrapper from '../../components/Wrapper';
import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import ContentBox from '../../components/ContentBox';
import styles from '../../styles.module.scss';
import { DatePicker } from 'react-date-picker';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../ContextProvider';
import { useGetManagers } from '../../api/hooks/useGetManagers';
import { normalizeManagers } from '../../helpers/normalizeManagers';
import Loader from '../../components/Loader';
import { useAdminGetManagerHistory } from '../../api/hooks/useAdminGetManagerHistory';
import { createFormData } from '../../helpers/createFormData';
import { statuses } from '../../const/statuses';
import stylesForStatus from '../../components/OpenLead/styles.module.scss';
import OpenLead from '../../components/OpenLead';
import { formatDate } from '../../helpers/formatDate';

export default function WorkLog() {
  useCheckRole(roles.admin);

  const statusColors = [stylesForStatus.statusColor0, stylesForStatus.statusColor1, stylesForStatus.statusColor2, stylesForStatus.statusColor3, stylesForStatus.statusColor4, stylesForStatus.statusColor5, stylesForStatus.statusColor6, stylesForStatus.statusColor7, stylesForStatus.statusColor8];

  const { managers, setManagers, setNotifyWarn, setNotifySuc } = useContext(Context);

  const {
    mutate: mutateGetManagers,
    isLoading: isLoadingGetManagers
  } = useGetManagers();

  const { mutate, isLoading } = useAdminGetManagerHistory();

  const [startDate, setStartDate] = useState(new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() - 1
  ));
  const [endDate, setEndDate] = useState(new Date());
  const [managerId, setManagerId] = useState();
  const [orders, setOrders] = useState();
  const [selectedLead, setSelectedLead] = useState(null);

  const getManagers = useCallback(() => {
    mutateGetManagers({}, {
      onSuccess: d => setManagers(normalizeManagers(d)),
    });
  }, [mutateGetManagers]);

  useEffect(() => {
    getManagers();
  }, [])

  const getOrders = useCallback(() => {
    mutate(createFormData({
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      manager_id: managerId
    }), {
      onSuccess: d => setOrders(d),
    })
  }, [mutate, startDate, endDate, managerId]);

  return (
    <Wrapper
      headerContent={
        <>
          {
            selectedLead &&
            <button
              onClick={
                () => setSelectedLead(null)
              }
              className={styles.inlineButton}
            >
              Закрыть карточку
            </button>
          }
        </>
      }
    >
      {
        selectedLead && <OpenLead getLeadsCallback={getOrders} lead={selectedLead} cardOption={2} />
      }

      {
        !selectedLead &&
        <>
          <ContentBox>
            <h2>Выберите менеджера и период даты, чтобы загрузить всех лидов с которыми он работал</h2>
            <div className={styles.marginTop1Rem}>
              С
              <DatePicker value={startDate} showLeadingZeros={true} onChange={setStartDate} />
            </div>
            <div className={styles.marginTop1Rem}>
              По
              <DatePicker value={endDate} showLeadingZeros={true} onChange={setEndDate} />
            </div>
            <div className={styles.marginTop1Rem}>
              Выберите менеджера
              <select onChange={e => setManagerId(e.target.value)}>
                <option disabled selected>Не выбрано</option>
                {
                  Object.keys(managers).map(key =>
                    <option selected={managers[key].id === managerId} key={key} value={key}>{managers[key].login}</option>
                  )
                }
              </select>
            </div>
            <button onClick={() => {
              if (!managerId) {
                return setNotifyWarn('Менеджер не выбран');
              }
              getOrders();
            }}>
              Загрузить
            </button>
            <Loader showing={isLoadingGetManagers} />
          </ContentBox>

          <ContentBox>
            <div className={styles.row}>
              <div className={styles.item}>
                <b>Дата и время</b>
              </div>
              <div className={`${styles.item}`}>
                <b>Тип обновления</b>
              </div>
              <div className={`${styles.item}`}>
                <b>Статус<br/>на момент записи</b>
              </div>
              <div className={`${styles.item}`}>
                <b>ID</b>
              </div>
              <div className={`${styles.item}`}>
                <b>Лид</b>
              </div>
              <div className={`${styles.item}`}>
                Кнопки
              </div>
            </div>
            {orders?.length < 1 && <p>Записи о работе отсутсвуют</p>}
            {
              orders?.length > 0 &&
              orders?.map(({date, type, lid, lid: { phone, id }, statusInMoment }) => (
                <div key={`${date} ${type} ${id}`} className={styles.row}>
                  <div className={styles.item}>
                    {formatDate(date)}
                  </div>
                  <div className={`${styles.item}`}>
                    {type == 0 ? 'Обновил статус' : 'Установил задачу'}
                  </div>
                  <div className={styles.item}>
                    <div className={statusColors[statusInMoment]}>{statuses[statusInMoment]}</div>
                  </div>
                  <div className={styles.item}>
                    {id}
                  </div>
                  <div className={styles.item}>
                    {phone}
                  </div>
                  <div className={`${styles.item}`}>
                    <button onClick={() => setSelectedLead(lid)}>Открыть лид</button>
                  </div>
                </div>
              ))
            }

            <Loader showing={isLoading} />
          </ContentBox>
        </>
      }
    </Wrapper>
  );
}