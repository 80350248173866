import React, { useEffect, useRef, useState } from 'react';
import _Modal from 'react-modal';
import useOutsideClick from '../../hooks/useOutsideClick';
import styles from './styles.module.scss';
import './styles.css';
import { useTheme } from '../../hooks/useThem';

export default function Modal({
  title,
  children,
  isOpen,
  parentSetter,
  canClosed = true,
  closedOnOutside = false,
  modalStyles
}) {
  const [modalIsOpen, setIsOpen] = useState(isOpen);
  const modalRef = useRef(null);
  useOutsideClick({
    ref: modalRef,
    callback: () =>  closedOnOutside && canClosed && parentSetter(false),
  });

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const { theme } = useTheme();

  return (
    <_Modal
      closeTimeoutMS={600}
      overlayClassName={styles.overlay}
      className={`${styles.modal} ${theme === 'dark' ? styles.dark : ''}`}
      isOpen={modalIsOpen}
      ariaHideApp={false}>
      <div ref={modalRef} style={modalStyles}>
        <div className={`${styles.head} ${theme === 'dark' ? styles.dark : ''}`}>
          <div className={`${styles.title} ${theme === 'dark' ? styles.dark : ''}`}>{title}</div>
          {canClosed && (
            <div onClick={() => parentSetter(false)} className={`${styles.close} ${theme === 'dark' ? styles.dark : ''}`}>
              ✕
            </div>
          )}
        </div>

        <div className={`${styles.body} ${theme === 'dark' ? styles.dark : ''}`}>{children}</div>
      </div>
    </_Modal>
  );
}
