import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Menu from '../Menu';
import styles from './styles.module.scss';
import { Context } from '../../ContextProvider';
import { useRemainder } from '../../hooks/useRemainder';
import { useTheme } from '../../hooks/useThem';

export default function Wrapper({ pageName, headerContent, children }) {
  const { isOpen } = useContext(Context);

  useRemainder();

  const { theme } = useTheme();

  return (
    <div className={`${styles.wrapper} ${theme === 'dark' ? styles.dark : ''}`}>
      <Menu />

      <div className={`${styles.body} ${isOpen && styles.isOpen}`}>
        <div className={styles.header}>
          <div className={styles.row}>
            <div className={`${styles.title} ${theme === 'dark' ? styles.dark : ''}`}>{pageName}</div>
            <div className={styles.headerContent}>{headerContent}</div>
          </div>
        </div>

        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
