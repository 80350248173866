import { createFormData } from '../../../helpers/createFormData';
import Loader from '../../Loader';
import Modal from '../../Modal';
import { useContext } from 'react';
import { Context } from '../../../ContextProvider';
import { useAdminDeleteLeads } from '../../../api/hooks/useAdminDeleteLeads';

export default function ModalDeleteLead({ isDeleteOpened, setIsDeleteOpened }) {
  const {
    setNotifySuc,
    updateParentResources,
    selectedLeads
  } = useContext(Context);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useAdminDeleteLeads();

  return (
    <Modal isOpen={isDeleteOpened} parentSetter={setIsDeleteOpened}>
      <button
        onClick={() => {
          mutateDelete(createFormData({ lids: selectedLeads }), {
            onSuccess: () => {
              setNotifySuc('Лиды успешно удалены');
              setIsDeleteOpened(false);
              updateParentResources();
            }
          })
        }}
      >
        Подтвердить удаление выбранных лидов
      </button>
      <Loader showing={isLoadingDelete} />
    </Modal>
  );
}