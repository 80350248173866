import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useGetManagerLeadHistory = () => {
  return useMutation(
    'GetManagerLeadHistory',
    data =>
      ApiService.request({ url: '/getManagerLeadHistory.php', data }),
  );
};
