import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminTgBotRemoveUser = () => {
  return useMutation(
    'useAdminTgBotRemoveUser',
    data =>
      ApiService.request({ url: '/tgBotRemoveUser.php', data }),
  );
};
