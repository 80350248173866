import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCheckRemainder } from '../api/hooks/useCheckRemainder';
import { Context } from '../ContextProvider';

export const useRemainder = () => {
  const { setNotify, taskMessage, setTaskMessage, user } = useContext(Context);
  const [tabIsFocused, setTabIsFocused] = useState(true);

  const onFocus = useCallback(() => setTabIsFocused(true), [setTabIsFocused]);
  const onBlur = useCallback(() => setTabIsFocused(false), [setTabIsFocused]);

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    console.log('user', user);
    if (taskMessage !== null) {
      if (audio?.readyState !== 0 && user?.isSoundOn === '1') {
        audio.play();
      }
      if (!tabIsFocused) {
        alert(taskMessage.text);
      }
      setNotify(taskMessage.text);
      setTaskMessage(null);
    }
  }, [taskMessage, user]);

  const audio = useMemo(() => new Audio(process.env.PUBLIC_URL + '/m.mp3'), []);

  useCheckRemainder();
}