import Wrapper from '../../components/Wrapper';
import ContentBox from '../../components/ContentBox';
import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import { useGetAdminLeadsStat } from '../../api/hooks/useGetAdminLeadsStat';
import Loader from '../../components/Loader';
import styles from '../../styles.module.scss';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useAdminAddLeads } from '../../api/hooks/useAdminAddLeads';
import { createFormData } from '../../helpers/createFormData';
import { Context } from '../../ContextProvider';

export default function AddLids() {
  useCheckRole(roles.admin);

  const { setNotifySuc, setNotifyWarn } = useContext(Context);

  const { mutate: mutateGetStat, isLoading: isLoadingGetStat } = useGetAdminLeadsStat();
  const { mutate, isLoading } = useAdminAddLeads();

  const [stat, setStat] = useState();
  const [textarea, setTextarea] = useState('');
  const [tag, setTag] = useState('');
  const [file, setFile] = useState(null);

  const getStat = useCallback(() => {
    mutateGetStat({}, { onSuccess: d => setStat(d) });
  }, [mutateGetStat]);

  useEffect(() => {
    getStat();
  }, [mutateGetStat]);

  return(
    <Wrapper>
      <ContentBox>
        <h2>Общее количество лидов в базе</h2>
        <span className={styles.label1}>Всего лидов: {stat?.allCount}</span>
        <span className={styles.label2}>Распределено: {stat?.appointedCount}</span>
        <span className={styles.label3}>Не распределено: {stat?.notAppointedCount}</span>
        <Loader showing={isLoadingGetStat} />
      </ContentBox>

      <ContentBox>
        <h2>Добавить лиды</h2>
        <textarea
          placeholder='Здесь можно вставить лиды, каждый с новой строки'
          onChange={e => setTextarea(e.target.value)}
        >
        </textarea>
        <div className={styles.marginTop1Rem}>Также, можно прикрепить .txt файл </div>
        <input type='file' onChange={e => setFile(e.target.files[0])} />
        <div>Лиды из обоих полей будут добавлены, дубли исключены.</div>
        <div className={styles.marginTop1Rem}>Tag для лидов</div>
        <input placeholder='Тег' onChange={e => setTag(e.target.value)} value={tag} />
        <button
          style={{
            padding: '1rem 3rem',
            width: '100%'
          }}
          onClick={() => {
            if (textarea.length < 1 && file === null) {
              return setNotifyWarn('Заполните данные формы');
            }
            mutate(createFormData({ file, field: textarea, tag }), {
              onSuccess: d => {
                setNotifySuc(d);
                getStat();
              }
            });
          }}
        >
          Добавить в базу
        </button>
        <Loader showing={isLoading} />
      </ContentBox>
    </Wrapper>
  );
}