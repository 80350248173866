import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminMoveLeadsFromManager = () => {
  return useMutation(
    'useAdminMoveLeadsFromManager',
    data =>
      ApiService.request({ url: '/adminMoveLidsFromManager.php', data }),
  );
};
