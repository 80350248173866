import { useMutation } from 'react-query';
import ApiService from '../ApiService';

export const useAdminTgBotGetUsers = () => {
  return useMutation(
    'useAdminTgBotGetUsers',
    data =>
      ApiService.request({ url: '/tgBotGetUsers.php', data }),
  );
};
