import Wrapper from '../../components/Wrapper';
import { useCheckRole } from '../../hooks/useCheckRole';
import { roles } from '../../const/roles';
import ContentBox from '../../components/ContentBox';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Context } from '../../ContextProvider';
import { useGetManagers } from '../../api/hooks/useGetManagers';
import { normalizeManagers } from '../../helpers/normalizeManagers';
import Loader from '../../components/Loader';
import styles from '../../styles.module.scss';
import ManagerCard from '../../components/ManagerCard';
import { useGetBlockedManagers } from '../../api/hooks/useGetBlockedManagers';
import { useGetAdmins } from '../../api/hooks/useGetAdmins';
import Modal from '../../components/Modal';
import { useAdminAddUser } from '../../api/hooks/useAdminAddUser';
import { createFormData } from '../../helpers/createFormData';

export default function Users() {
  useCheckRole(roles.admin);

  const [blockedManagers, setBlockedManagers] = useState({});
  const [admins, setAdmins] = useState({});
  const [isCreateUserOpened, setIsCreateUserOpened] = useState(false);

  const { managers, setManagers, setUpdateParentResources, setNotifyWarn, setNotifySuc } = useContext(Context);
  const {
    mutate: mutateGetManagers,
    isLoading: isLoadingGetManagers
  } = useGetManagers();
  const { mutate: mutateBlockedManagers, isLoading: isLoadingBlockedManagers } = useGetBlockedManagers();
  const { mutate: mutateAdmins, isLoading: isLoadingAdmins } = useGetAdmins();
  const { mutate: mutateAddUser, isLoading: isLoadingAddUser } = useAdminAddUser();

  const getManagers = useCallback(() => {
    mutateGetManagers({}, {
      onSuccess: d => setManagers(normalizeManagers(d)),
    });
  }, [mutateGetManagers]);

  const getBlockedManagers = useCallback(() => {
    mutateBlockedManagers({}, {
      onSuccess: d => setBlockedManagers(normalizeManagers(d)),
    });
  }, [mutateBlockedManagers]);

  const getAdmins = useCallback(() => {
    mutateAdmins({}, {
      onSuccess: d => setAdmins(normalizeManagers(d)),
    });
  }, [mutateBlockedManagers]);

  const allUpdate = useCallback(() => {
    getManagers();
    getBlockedManagers();
  }, [getManagers, getBlockedManagers]);

  useEffect(() => {
    getManagers();
    getBlockedManagers();
    getAdmins();
    setUpdateParentResources(() => allUpdate);
  }, [allUpdate]);

  return (
    <Wrapper headerContent={
      <button onClick={() => setIsCreateUserOpened(true)}>+ Новый пользователь</button>
    }>
      <ContentBox>
        <h2>Активные менеджеры</h2>
        <div className={styles.flexContainer}>
          {
            Object.values(managers).map(i =>
              <ManagerCard
                key={i.id}
                manager={i}
              />
            )
          }
        </div>
        <Loader showing={isLoadingGetManagers} />
      </ContentBox>

      <ContentBox>
        <h2>Неактивные менеджеры, у которых еще остались лиды</h2>
        <div className={styles.flexContainer}>
          {
            Object.values(blockedManagers).map(i =>
              <ManagerCard
                key={i.id}
                manager={i}
                type={0}
              />
            )
          }
        </div>
        <Loader showing={isLoadingBlockedManagers} />
      </ContentBox>

      <ContentBox>
        <h2>Админы</h2>
        <div className={styles.flexContainer}>
          {
            Object.values(admins).map(i =>
              <ManagerCard
                key={i.id}
                manager={i}
                type={6}
              />
            )
          }
        </div>
        <Loader showing={isLoadingAdmins} />
      </ContentBox>

      <Modal isOpen={isCreateUserOpened} parentSetter={setIsCreateUserOpened} title='Создать нового пользователя'>
        <form onSubmit={e => {
          e.preventDefault();
          const { target: { elements: { login, password, fio, tg, role } } } = e;
          if (login.value.length < 5 || password.value.length < 5) {
            return setNotifyWarn('Слишком короткие логин или пароль');
          }
          mutateAddUser(createFormData({
            fio: fio.value,
            tg: tg.value,
            role: role.value,
            login: login.value,
            pass: password.value
          }), {
            onSuccess: () => {
              if (role.value === '2') {
                getManagers();
              }
              if (role.value === '1') {
                getAdmins();
              }
              setIsCreateUserOpened(false);
              setNotifySuc('Пользователь успешно создан');
            }
          })
        }}>
          <div className={styles.marginTop1Rem}>
            Логин пользователя
            <input name='login' />
          </div>
          <div className={styles.marginTop1Rem}>
            Пароль пользователя
            <input name='password' />
          </div>
          <div className={styles.marginTop1Rem}>
            ФИО пользователя
            <input name='fio' />
          </div>
          <div className={styles.marginTop1Rem}>
            Телеграм пользователя
            <input name='tg' />
          </div>
          <div className={styles.marginTop1Rem}>
            Роль
            <select name='role'>
              {
                Object.entries(roles).map(([name, id]) => (
                  <option selected={id === '2'} value={id}>{name}</option>
                ))
              }
            </select>
          </div>
          <button>Добавить пользователя</button>
        </form>
        <Loader showing={isLoadingAddUser} />
      </Modal>
    </Wrapper>
  );
}